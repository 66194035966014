<template>
  <div>
    <div v-if="isEmptyData" class="no-data-message">
      <NoDataMessage />
    </div>
    <div v-else>
      <div v-if="$route.query.debug">
        <div>
          <button
            class="button toggle-button"
            :class="{
              'is-primary': chartType == 'line',
            }"
            @click="chartType = 'line'"
          >
            Line
          </button>
          <button
            :class="{
              'is-primary': chartType == 'column',
            }"
            class="button toggle-button"
            @click="chartType = 'column'"
          >
            Bar
          </button>
        </div>
        <div>
          <button
            class="button toggle-button"
            :class="{
              'is-primary': stacked,
            }"
            @click="stacked = true"
          >
            Stacked
          </button>
          <button
            :class="{
              'is-primary': !stacked,
            }"
            class="button toggle-button"
            @click="stacked = false"
          >
            Unstacked
          </button>
        </div>
      </div>
      <div>
        <MultiYearChart
          :data="data"
          :min-year="minYear"
          :max-year="maxYear"
          :type="chartType"
          :stacked="stacked"
          :colors="colors"
          :y-label="yLabel"
          :filename="filename"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";
import MultiYearChart from "@/components/keyFigures/MultiYearChart.vue";
import NoDataMessage from "@/components/NoDataMessage.vue";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  minYear: {
    type: Number,
    default: 2005,
  },
  maxYear: {
    type: Number,
    default: 2023,
  },
  yLabel: {
    type: String,
    default: "Projekte",
  },
  filename: {
    type: String,
  },
});

const chartType = ref("line");
const stacked = ref(false);
const colors = ref("snf");

const isEmptyData = computed(() => {
  return (
    props.data.length === 0 ||
    props.data.filter((e) => e.label !== "Total").every((e) => e.count === 0)
  );
});
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";
@import "../../assets/css/mixins";
@import "../../assets/css/bulma_utils";
@import "../../../node_modules/bulma/sass/elements/button";

.toggle-button {
  margin-bottom: 8px;
  margin-right: 8px;
}

.no-data-message {
  font-size: 20px;
  font-weight: 700;
  padding: 0 24px;
  margin-bottom: 48px;
  margin-left: -12px;
}
</style>
