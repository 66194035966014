// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
import * as _ from "lodash";
import { capitalizeWords, numberToStringFormat } from "@/utils";

export const snfColors = [
  "#83d0f5", // $snf-blue-light
  "#fbbe5e", // $snf-yellow
  "#71b294", // $snf-green
  "#f08262", // $snf-red-light
  "#9d90b9", // $snf-violet
  "#5298bd", // $snf-blue
  "#fddeae", // $snf-yellow-light
  "#b8d8c9", // $snf-green-light
  "#cec7dc", // $snf-violet-light
];

export const blueShades = ["#89f5ff", "#59caf8", "#19a1cd", "#017aa4", "#00567d"];

const makeAxes = (chart, type, yLabel, language) => {
  chart.dateFormatter.inputDateFormat = "yyyy";
  let yearAxis = chart.xAxes.push(new window.am4charts.DateAxis());
  yearAxis.dataFields.category = "CallDecisionYear";
  yearAxis.renderer.minGridDistance = 30;
  let valueAxis = chart.yAxes.push(new window.am4charts.ValueAxis());
  valueAxis.title.text = capitalizeWords(yLabel);
  valueAxis.min = 0;

  valueAxis.maxPrecision = 0; // Ensures no decimal values (for non-binary)

  valueAxis.renderer.labels.template.adapter.add("textOutput", (text, target) => {
    if (target.dataItem.value) {
      return numberToStringFormat(target.dataItem.value, language);
    }
    return text;
  });

  valueAxis.cursorTooltipEnabled = false;

  switch (type) {
    case "line":
      yearAxis.startLocation = 0.3;
      yearAxis.endLocation = 0.7;
      yearAxis.renderer.grid.template.location = 0.5;
      break;
    case "column":
    default:
      yearAxis.startLocation = 0;
      yearAxis.endLocation = 1;
      break;
  }
};

const addSeries = (
  chart,
  label,
  data,
  stacked,
  type,
  index,
  language,
  minYear = 2005,
  maxYear = 2023,
) => {
  let series;
  switch (type) {
    case "line":
      series = chart.series.push(new window.am4charts.LineSeries());
      series.strokeWidth = 3;
      break;
    case "column":
    default:
      series = chart.series.push(new window.am4charts.ColumnSeries());
      break;
  }
  series.name = label;
  // possible values: Funding, Funding_Submitted, Grants, Grants_Submitted
  series.dataFields.valueY = "count";
  series.dataFields.dateX = "CallDecisionYear";

  series.data = getProjects(data, label, minYear, maxYear);
  series.stacked = stacked;

  series.adapter.add("tooltipText", function (text, target) {
    const valueY = target.tooltipDataItem.values.valueY.value;
    const year = new Date(target.tooltipDataItem.values.dateX.value).getFullYear();
    const formattedValueY = numberToStringFormat(valueY, language);
    return `${label} ${year} (${formattedValueY})`;
  });

  if (index > 10) {
    series.hidden = true;
  }

  let totalCount = 0;
  series.data.forEach((item) => {
    totalCount += item.count;
  });
  series.name = `${label} (${numberToStringFormat(totalCount, language)})`;
  series.snfTotalCount = totalCount;

  // series.legendSettings.valueText = "{valueY}";
  if (stacked) {
    series.fillOpacity = 1;
    series.sequencedInterpolation = true;
  }

  return series;
};

export const getProjects = (data, label, minYear = 2005, maxYear = 2023) => {
  const projects = data.filter((i) => i.label === label);
  const result = [...projects];

  // fill out missing years with 0
  for (let i = minYear; i <= maxYear; i++) {
    const project = projects.find((p) => p.CallDecisionYear === i);
    if (!project) {
      result.push({
        CallDecisionYear: i,
        count: 0,
        label,
      });
    }
  }

  return _.orderBy(
    _.map(result, (r) => {
      return {
        ...r,
        CallDecisionYear: `${r.CallDecisionYear}`, // to make date formatting work
      };
    }),
    "CallDecisionYear",
  );
  // result
  //   .sort((a, b) => a.CallDecisionYear - b.CallDecisionYear)
  //   .map((i) => ({
  //     ...i,
  //     CallDecisionYear: `${i.CallDecisionYear}`, // to make date formatting work
  //   }));
  // return result;
};

export const createChart = (
  { el, chart, yLabel, data, stacked, type, colors, minYear, maxYear, language = "en" },
  onReady,
) => {
  if (chart !== null) {
    chart.dispose();
  }
  // const minCallDecisionYear = _.minBy(data, "CallDecisionYear").CallDecisionYear;
  // console.log("minCallDecisionYear", minCallDecisionYear);
  // console.log("maxCallDecisionYear", maxCallDecisionYear);

  // Create chart instance
  window.am4core.addLicense("CH262563860");
  chart = window.am4core.create(el, window.am4charts.XYChart);

  if (onReady) {
    chart.events.on("ready", onReady);
  }

  let palette;
  if (colors === "blue") {
    palette = blueShades;
  } else {
    palette = snfColors;
  }
  palette = palette.concat(palette, palette);
  chart.colors.list = palette.map((color) => window.am4core.color(color));
  chart.height = window.am4core.percent(100);

  makeAxes(chart, type, yLabel, language);

  const seriesElements = _.uniq(
    data.filter((i) => i.label !== "Total").map((i) => i.label),
  );
  let seriesElementsWithTotal = _.map(seriesElements, (label) => {
    const seriesData = getProjects(data, label);
    const totalCount = _.sumBy(seriesData, "count");
    return {
      label,
      count: totalCount,
    };
  });

  // filter out zero counts
  seriesElementsWithTotal = _.orderBy(
    seriesElementsWithTotal.filter((i) => i.count > 0),
    "count",
    "desc",
  );

  const series = seriesElementsWithTotal.map(({ label, count }, index) => {
    return addSeries(
      chart,
      label,
      data,
      stacked,
      type,
      index,
      language,
      minYear,
      maxYear,
    );
  });

  chart.legend = new window.am4charts.Legend();
  chart.legend.maxHeight = 300;
  chart.legend.layout = "grid";
  chart.legend.minColumns = 2;
  chart.legend.width = window.am4core.percent(100);

  chart.legend.scrollable = true;
  // Accessing the scrollbar directly from the legend
  let scrollbar = chart.legend.scrollbar;

  // Customizing the scrollbar
  scrollbar.background.fill = window.am4core.color("#cccccc"); // Change the background color of the scrollbar
  scrollbar.thumb.background.fill = window.am4core.color("#888888"); // Change the color of the thumb
  scrollbar.minHeight = 20; // For horizontal scrollbars, adjust the height instead

  chart.cursor = new window.am4charts.XYCursor();
  chart.cursor.maxTooltipDistance = 20;

  return chart;
};
