import * as _ from "lodash";
import axios from "axios";

import { numberToStringFormat } from "./utils";
import { objectAttributeInLanguage } from "@/mixins";
import { getAllMatches, parseSentence } from "@/filterSentenceParser";

const langSuffixTable = {
  de: "_De",
  fr: "_Fr",
  en: "_En",
};

export const CONTINENTS = ["AF", "AS", "EU", "NA", "SA", "OC"];

export function buildEntityQuery(
  mainPart,
  numRows = 1000,
  solrCore = "search",
  category = "stats",
) {
  let basePart = `/solr/${solrCore}/select/?rows=${numRows}`;
  if (mainPart) {
    return `${basePart}&q=Category:${category}+AND+${mainPart}`;
  }
  return basePart;
}

export function buildQuery(
  entityPart,
  selectedParts,
  ignoreParts,
  numRows = 2000,
  solrCore = "search",
  category = "stats",
) {
  const queryParts = _.chain(selectedParts)
    .keys()
    .map((partsId) => {
      if (_.includes(ignoreParts, partsId)) {
        return null;
      }

      const value = selectedParts[partsId];

      if (value.solrPartOverride) {
        return value.solrPartOverride;
      }

      // check for date range which is in two separate `selectedParts`
      const value2 = _.find(selectedParts, (v) => {
        return v.solrAttribute === value.solrAttribute && v.id !== value.id;
      });
      if (value2) {
        const min = Math.min(value.solrValue, value2.solrValue);
        const max = Math.max(value.solrValue, value2.solrValue);
        return `${value.solrAttribute}:[${min} TO ${max}]`;
      }

      if (value.solrValue === "-" || value.solrAttribute === "") {
        return null;
      }

      return buildAttributePart(value);
    })
    .filter((k) => {
      return k;
    })
    .uniq()
    .value();

  const baseQuery = buildEntityQuery(entityPart, numRows, solrCore, category);

  if (queryParts.length > 0) {
    return baseQuery + `+AND+${queryParts.join("+AND+")}`;
  }
  return baseQuery;
}

export function buildAttributePart(selected) {
  return `${selected.solrAttribute}:"${selected.solrValue}"`;
}

const dataFields = [
  "Grants",
  "Grants_Submitted",
  "Funding",
  "Funding_Submitted",
  "Number",
  "CountryIsoCode",
  "ContinentCode",
  "CallDecisionYear",
  "DecisionYear",
];

export function createDataFromResponse(solrResponse, levelInfo) {
  let level1LangFields = [];
  if (levelInfo.level1Field) {
    level1LangFields = ["_De", "_En", "_Fr"].map((lang) => {
      return `${levelInfo.level1Field}${lang}`;
    });
  }

  let level2LangFields = [];
  if (levelInfo.level2Field) {
    level2LangFields = ["_De", "_En", "_Fr"].map((lang) => {
      return `${levelInfo.level2Field}${lang}`;
    });
  }

  const pickFields = [...level1LangFields, ...level2LangFields, ...dataFields];
  return _.map(
    _.sortBy(solrResponse.response.docs, [
      `${levelInfo.level1Field}SortCode`,
      `${levelInfo.level2Field}SortCode`,
      `${levelInfo.level1Field}_De`,
      `${levelInfo.level1Field}_Fr`,
      `${levelInfo.level1Field}_En`,
      `${levelInfo.level2Field}_De`,
      `${levelInfo.level2Field}_Fr`,
      `${levelInfo.level2Field}_En`,
      `${levelInfo.level1Field}Id`,
      `${levelInfo.level1Field}Code`,
      `${levelInfo.level2Field}Id`,
      `${levelInfo.level2Field}Code`,
    ]),
    (d) => {
      return _.pick(d, pickFields);
    },
  );
}

export function createPictureData(
  parsedSolrResponse,
  levelInfo,
  selectedParts,
  selectedLang = "de",
) {
  let numberDataField = "Number";

  if (!selectedParts["*1"].solrAttribute && !selectedParts["*2"].solrAttribute) {
    const s1 = selectedParts["*1"].solrValue;
    const s2 = selectedParts["*2"].solrValue;

    if (s1 === "1" && s2 === "1") {
      numberDataField = "Grants";
    } else if (s1 === "2" && s2 === "1") {
      numberDataField = "Grants_Submitted";
    } else if (s1 === "1" && s2 === "2") {
      numberDataField = "Funding";
    } else if (s1 === "2" && s2 === "2") {
      numberDataField = "Funding_Submitted";
    }
  } else if (!selectedParts["*1"].solrAttribute) {
    const s1 = selectedParts["*1"].solrValue;
    if (s1 === "1") {
      numberDataField = "Grants";
    } else if (s1 === "2") {
      numberDataField = "Funding";
    }
  }

  const langSuffix = langSuffixTable[selectedLang];

  let levelField = levelInfo.level2Field + langSuffix;
  if (
    levelInfo.fieldIndex &&
    selectedParts[levelInfo.fieldIndex].solrAttribute.replace(/(Id$|Code$)/, "") ===
      levelInfo.level2Field
  ) {
    levelField = levelInfo.level1Field + langSuffix;
  }

  let data = _.filter(
    _.map(parsedSolrResponse, (p) => {
      return _.assign(
        {
          label: p[levelField],
          count: parseInt(p[numberDataField] || 0, 10),
        },
        _.pick(p, dataFields),
      );
    }),
    (p) => {
      return p.label;
    },
  );

  return data.map((d) => {
    if (d.DecisionYear) {
      d.CallDecisionYear = d.DecisionYear;
    }
    return d;
  });
}

export function createSelectedPartsInitial(parsedEntries) {
  let selectedParts = {};
  Object.keys(parsedEntries).forEach((k) => {
    selectedParts[k] =
      parsedEntries[k].selectionValues[parsedEntries[k].selectedIndex || 0];
  });
  return selectedParts;
}

export function createSelectedSentence(parsedSentence, selectedParts) {
  let result = parsedSentence;
  _.forEach(["*1", "*2", "*3", "*4", "*5", "*6", "*7"], (n) => {
    if (selectedParts[n]) {
      result = result.replace(n, selectedParts[n].text);
    }
  });
  return result;
}

function getResultNumbers(selectedParts, pictureData) {
  let numberField = "Number";
  let moneyField = "";

  // remove `Total` entry from data
  const filteredPictureData = pictureData.filter((p) => {
    return p.label !== "Total";
  });

  if (!selectedParts["*1"].solrAttribute && selectedParts["*2"].solrAttribute) {
    numberField = "Grants";
    moneyField = "Funding";
  } else if (
    !selectedParts["*1"].solrAttribute &&
    selectedParts["*1"].solrValue === "1"
  ) {
    numberField = "Grants";
    moneyField = "Funding";
  } else if (
    !selectedParts["*1"].solrAttribute &&
    selectedParts["*1"].solrValue === "2"
  ) {
    numberField = "Grants_Submitted";
    moneyField = "Funding_Submitted";
  }

  let number = _.sumBy(filteredPictureData, (p) => {
    return parseInt(p[numberField], 10) || 0;
  });
  let moneySum = _.sumBy(filteredPictureData, (p) => {
    return parseInt(p[moneyField] || 0, 10);
  });

  // const totalEntry = _.find(filteredPictureData, { label: "Total" });
  // if (totalEntry) {
  //   number = parseInt(totalEntry[numberField], 10);
  //   moneySum = parseInt(totalEntry[moneyField], 10);
  // }

  return { totalNumber: number, totalMoneySum: moneySum };
}

export function createResultSentence(
  cfSolrSentences,
  selectedParts,
  totalNumber,
  totalMoneySum,
  selectedLanguage = "de",
  solrEntity = "",
) {
  let resultEntry = _.find(cfSolrSentences, {
    Entity: "CoreFigures_ResultSentences",
    Condition: `*1==${selectedParts["*1"].solrValue}`,
  });

  if (!resultEntry) {
    // FIXME: workaround for selectible success chart
    resultEntry = _.find(cfSolrSentences, {
      Entity: "CoreFigures_ResultSentences",
      Condition: `*1==2`,
    });
  }

  let result = resultEntry[langFilterSentenceKey[selectedLanguage]];

  // FIXME: hardcoded fix for multiple and single years
  const resultSentenceMultipleYearsWorkaround = true;
  if (resultSentenceMultipleYearsWorkaround) {
    if (
      [
        "ResearchInstitutionsFundingInstrumentsL1",
        "FundingInstruments",
        "Disciplines",
      ].includes(solrEntity)
    ) {
      if (selectedParts["*5"].solrValue !== selectedParts["*6"].solrValue) {
        result = result.replace("in *5", "between *5 and *6");
        result = result.replace("Im Jahr *5", "In den Jahren *5 bis *6");
        result = result.replace("En *5", "Entre *5 et *6");
      }
    }
    if (solrEntity === "SupplementaryFunding") {
      if (selectedParts["*2"].solrValue !== selectedParts["*3"].solrValue) {
        result = result.replace("In *2", "Between *2 and *3");
        result = result.replace("Im Jahr *2", "In den Jahren *2 bis *3");
        result = result.replace("En *2", "Entre *2 et *3");
      }
    }
    if (solrEntity === "Internationality") {
      if (selectedParts["*3"].solrValue === selectedParts["*4"].solrValue) {
        result = result.replace("Between *3 and *4", "In *3");
        result = result.replace("In den Jahren *3 bis *4", "Im Jahr *3");
        result = result.replace("Entre *3 et *4", "En *3");
      }
    }
  }

  _.forEach(["*1", "*2", "*3", "*4", "*5", "*6", "*7"], (n) => {
    if (selectedParts[n]) {
      result = result.replace(n, selectedParts[n].text);
    }
  });

  result = result.replace(
    "#ANZAHL#",
    `<span class="cf-result-number">${numberToStringFormat(
      totalNumber,
      selectedLanguage,
    )}</span>`,
  );
  result = result.replace(
    "#SUMME#",
    `<span class="cf-result-number" data-original="${totalMoneySum}">${numberToStringFormat(
      totalMoneySum,
      selectedLanguage,
    )}</span>`,
  );

  return result;
}

export function createResultSentenceMedian(
  cfSolrSentences,
  selectedParts,
  pictureData,
  selectedLanguage = "de",
) {
  let result = _.find(cfSolrSentences, {
    Entity: "CoreFigures_ResultSentences",
    Condition: `*1==Total`,
  })[langFilterSentenceKey[selectedLanguage]];

  _.forEach(["*1", "*2", "*3", "*4", "*5", "*6", "*7"], (n) => {
    if (selectedParts[n]) {
      result = result.replace(n, selectedParts[n].text);
    }
  });

  if (pictureData && pictureData.length) {
    const medianValue = Math.floor(_.sumBy(pictureData, "value") / 2);
    let runningValue = 0;
    let medianAge = pictureData[0].name;

    for (let d of pictureData) {
      runningValue += d.value;
      if (runningValue >= medianValue) {
        medianAge = d.name;
        break;
      }
    }

    result = result.replace(
      "#AGE#",
      `<span class="cf-result-number">${medianAge}</span>`,
    );
    result = result.replace(
      "#ANZAHL#",
      `<span class="cf-result-number">${numberToStringFormat(
        _.sumBy(pictureData, "value"),
        selectedLanguage,
      )}</span>`,
    );
  } else {
    result = result
      .replace("#AGE#", `<span class="cf-result-number">0</span>`)
      .replace("#ANZAHL#", `<span class="cf-result-number">0</span>`);
  }

  return result;
}

export function querySentences(
  sentenceEntity,
  solrCore = "search",
  singleYear = false,
) {
  const filterSentences = {
    Demographics: [
      {
        Entity: "CoreFigures_FilterSentences",
        Page: "Demographics",
        German:
          "Altersverteilung der *2[{Verantwortlichen und Partner/innen;GrantRoleSortNumber;1},{Projektmitarbeitenden;GrantRoleSortNumber;2}]  *1[{Alle;Gender;Total}, {Frauen;Gender;f}, {Männer;Gender;m},{non-binär;Gender;x}, {Vergleich Frauen und Männer;Gender;Total}] im Jahr *3[2024--2005]",
        French:
          "Répartition par age des *2[{responsables et partenaires de projets;GrantRoleSortNumber;1},{collaborateurs;GrantRoleSortNumber;2}] *1[{tou·tes;Gender;Total},{femmes;Gender;f},{hommes;Gender;m},{non-binaire;Gender;x},{comparaison femmes et hommes;Gender;Total}] en *3[2024--2005]",
        English:
          "Age distribution of *1[{all;Gender;Total},{female;Gender;f},{male;Gender;m},{non binary;Gender;x},{comparison female and male;Gender;Total}] *2[{applicants and project partners;GrantRoleSortNumber;1},{employees;GrantRoleSortNumber;2}] in *3[2024--2005]",
      },
      {
        Condition: "*1==Total",
        Entity: "CoreFigures_ResultSentences",
        Page: "Demographics",
        German: "Die #ANZAHL# *2 im Jahr *3 waren im Median #AGE# Jahre alt.",
        French: "Les #ANZAHL# *2 en *3 ont un age médian de #AGE# ans.",
        English: "The median age of the #ANZAHL# *2 in *3 was #AGE# years.",
      },
      {
        Condition: "*1==f",
        Entity: "CoreFigures_ResultSentences",
        Page: "Demographics",
        German: "Die  #ANZAHL# *2 (*1) im Jahr *3 waren im Median #AGE# Jahre alt.",
        French: "Les #ANZAHL# *2 (*1) en *3 ont un age médian de #AGE# ans.",
        English: "The median age of the #ANZAHL# *1 *2 in *3 was #AGE# years.",
      },
      {
        Condition: "*1==m",
        Entity: "CoreFigures_ResultSentences",
        Page: "Demographics",
        German: "Die  #ANZAHL# *2 (*1) im Jahr *3 waren im Median #AGE# Jahre alt.",
        French: "Les #ANZAHL# *2 (*1) en *3 ont un age médian de #AGE# ans.",
        English: "The median age of the #ANZAHL# *1 *2 in *3 was #AGE# years.",
      },
    ],
    FundingInstruments: [
      {
        Entity: "CoreFigures_FilterSentences",
        English:
          "*1[{Approved;1},{Requested;2}] *2[{grants;1},{funding;2}] from *3[{all applicants;ResponsibleApplicantGender;Total},{women;ResponsibleApplicantGender;f},{men;ResponsibleApplicantGender;m},{non binary;ResponsibleApplicantGender;x}] in *4[{All funding schemes;FundingInstrumentReportingId;00000000-0000-0000-0000-000000000000},{Projects;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{Careers;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{Programmes;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{Infrastructure;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{Science Communication;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] between *5[2024--2005] and *6[2024--2005]",
        French:
          "*4[{Tous les instruments d’encouragement;FundingInstrumentReportingId;00000000-0000-0000-0000-000000000000},{Projets;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{Carrières;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{Programmes;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{Infrastructures;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{Communication scientifique;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] *2[{Projets;1},{Moyens financiers;2}] *1[{approuvés;1},{demandés;2}] par des *3[{requérant·es;ResponsibleApplicantGender;Total},{femmes;ResponsibleApplicantGender;f},{hommes;ResponsibleApplicantGender;m},{non-binaire;ResponsibleApplicantGender;x}] entre *5[2024--2005] et *6[2024--2005]",
        Page: "FundingInstruments",
        German:
          "*4[{Alle Förderungsinstrumente;FundingInstrumentReportingId;00000000-0000-0000-0000-000000000000},{Projektförderung;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{Karriereförderung;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{Programmförderung;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{Infrastrukturförderung;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{Wissenschaftskommunikation;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] *1[{Bewilligte;1},{Beantragte;2}] *2[{Projekte;1},{finanzielle Mittel;2}] von *3[{allen Gesuchstellenden;ResponsibleApplicantGender;Total},{Frauen;ResponsibleApplicantGender;f},{Männern;ResponsibleApplicantGender;m},{non-binär;ResponsibleApplicantGender;x}] in den Jahren *5[2024--2005] bis *6[2024--2005]",
      },
      {
        Condition: "*1==1",
        Entity: "CoreFigures_ResultSentences",
        Page: "FundingInstruments",
        German:
          "Im Jahr *5 bewilligte der SNF insgesamt #ANZAHL# neue Projekte mit einem bewilligten Betrag von #SUMME# CHF von *3 in *4 (ohne NFS).",
        English:
          "The SNSF approved #ANZAHL# new grants with a total approved sum of CHF #SUMME# from *3 in *4 (excluding NCCRs) in *5.",
        French:
          "En *5 le FNS a approuvé un total de #ANZAHL# nouveaux projets, soumises par des *3 dans *4 (sans PRN), pour un montant approuvé de #SUMME# CHF.",
      },
      {
        Condition: "*1==2",
        Entity: "CoreFigures_ResultSentences",
        Page: "FundingInstruments",
        German:
          "Im Jahr *5 evaluierte der SNF insgesamt #ANZAHL# Gesuche mit einem beantragten Betrag von #SUMME# CHF von *3 in *4 (ohne NFS).",
        English:
          "The SNSF evaluated #ANZAHL# applications with a total requested sum of CHF #SUMME# from *3 in *4 (excluding NCCRs) in *5.",
        French:
          "En *5 le FNS a evalué un total de #ANZAHL# requêtes, soumises par des *3 dans *4 (sans PRN) pour un montant requis de #SUMME# CHF.",
      },
    ],
    ResearchInstitutions: [
      {
        Entity: "CoreFigures_FilterSentences",
        English:
          "*3[{All Research Institutions;ResearchInstitutionId;00000000-0000-0000-0000-000000000000},{Cantonal Universities; ResearchInstitutionTypeId;4A03FAEA-CC55-4B6B-AF77-D9DC245C2136},{ETH Domain;ResearchInstitutionTypeId;6EE0A15D-934B-416F-BFB6-0F0B5638EDB3},{Universities of Applied Sciences;ResearchInstitutionTypeId;81AD9E73-3CCC-47B5-8029-B8DF23A3400A},{Universities of Teacher Education;ResearchInstitutionTypeId;51E83E41-F33D-4445-9B9A-C0B987BFC2EE},{Other Research Institutions;ResearchInstitutionTypeId;A9B7FC67-8BDE-4E18-817F-44F2099A0941}] *1[{Approved;1},{Requested;2}] *2[{grants;1},{funding;2}] in *4[{all;FundingInstrumentGaLevel1Id;00000000-0000-0000-0000-000000000000},{project;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{career;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{programme;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{infrastructure;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{science communication;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] funding schemes in between *5[2024--2005] and *6[2024--2005]",
        French:
          "*3[{Toutes les institutions de recherche;ResearchInstitutionId;00000000-0000-0000-0000-000000000000},{Universités cantonales;ResearchInstitutionTypeId;4A03FAEA-CC55-4B6B-AF77-D9DC245C2136},{Domaine des EPF;ResearchInstitutionTypeId;6EE0A15D-934B-416F-BFB6-0F0B5638EDB3},{Haute écoles spécialisées;ResearchInstitutionTypeId;81AD9E73-3CCC-47B5-8029-B8DF23A3400A},{Haute écoles pédagogiques;ResearchInstitutionTypeId;51E83E41-F33D-4445-9B9A-C0B987BFC2EE},{Autres institutions;ResearchInstitutionTypeId;A9B7FC67-8BDE-4E18-817F-44F2099A0941}] *2[{Projets;1},{Moyens financiers;2}] *1[{approuvés;1},{demandés;2}] dans *4[{l’ensemble des instruments d’encouragement;FundingInstrumentGaLevel1Id;00000000-0000-0000-0000-000000000000},{l’encouragement de projets;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{l’encouragement de carrières;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{les programmes;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{le soutien des infrastructures;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{le soutien de la communication scientifique;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] entre *5[2024--2005] et *6[2024--2005]",
        Page: "ResearchInstitutions",
        German:
          "*3[{Alle Forschungsinstitutionen;ResearchInstitutionId;00000000-0000-0000-0000-000000000000},{Kantonale Universitäten;ResearchInstitutionTypeId;4A03FAEA-CC55-4B6B-AF77-D9DC245C2136},{ETH-Bereich;ResearchInstitutionTypeId;6EE0A15D-934B-416F-BFB6-0F0B5638EDB3},{Fachhochschulen;ResearchInstitutionTypeId;81AD9E73-3CCC-47B5-8029-B8DF23A3400A},{Pädagogische Hochschulen;ResearchInstitutionTypeId;51E83E41-F33D-4445-9B9A-C0B987BFC2EE},{Andere Institutionen;ResearchInstitutionTypeId;A9B7FC67-8BDE-4E18-817F-44F2099A0941}] *1[{Bewilligte;1},{Beantragte;2}] *2[{Projekte;1},{finanzielle Mittel;2}] in *4[{allen Förderungsinstrumenten;FundingInstrumentGaLevel1Id;00000000-0000-0000-0000-000000000000},{der Projektförderung;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{der Karriereförderung;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{der Programmförderung;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{der Infrastrukturförderung;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{der Wissenschaftskommunikation;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] in den Jahren *5[2024--2005] bis *6[2024--2005]",
      },
      {
        Condition: "*1==1",
        Entity: "CoreFigures_ResultSentences",
        Page: "ResearchInstitutions",
        German:
          "Im Jahr *5 bewilligte der SNF insgesamt #ANZAHL# neue Projekte mit einem bewilligten Betrag von #SUMME# CHF von Forschenden aus *3 in *4 (ohne NFS).",
        English:
          "The SNSF approved #ANZAHL# new grants with a total approved sum of CHF #SUMME# from researchers at *3 in *4 funding schemes (excluding NCCRs) in *5.",
        French:
          "En *5 le FNS a approuvé un total de #ANZAHL# nouveaux projets de scientifiques *3 dans *4 (sans PRN) pour un montant approuvé de #SUMME# CHF.",
      },
      {
        Condition: "*1==2",
        Entity: "CoreFigures_ResultSentences",
        Page: "ResearchInstitutions",
        German:
          "Im Jahr *5 evaluierte der SNF insgesamt #ANZAHL# Gesuche mit einem beantragten Betrag von #SUMME# CHF von Forschenden aus *3 in *4 (ohne NFS).",
        English:
          "The SNSF evaluated #ANZAHL# applications with a total requested sum of CHF #SUMME# from researchers at *3 in *4 funding schemes (excluding NCCRs) in *5.",
        French:
          "En *5 le FNS a evalué un total de #ANZAHL# requêtes de scientifiques *3 dans *4 (sans PRN) pour un montant requis de #SUMME# CHF.",
      },
    ],
    Disciplines: [
      {
        Entity: "CoreFigures_FilterSentences",
        English:
          "*3[{All Disciplines;MainDisciplineLevel2Id;00000000-0000-0000-0000-000000000000},{Humanities and Social Sciences;MainDisciplineLevel1Id;2FCD3F76-FE21-4BFB-9C3C-824472444C34},{Mathematics, Natural- and Engineering Sciences;MainDisciplineLevel1Id;735199F4-7EC1-4936-8282-9D5D0DCFF566},{Biology and Medicine;MainDisciplineLevel1Id;CEC00901-F216-42F6-93BF-8066B685BFAF},{Multi-domain;MainDisciplineLevel1Id;E21089CE-D667-4257-B9ED-C5C14F171194}] *7[{mySNF Disciplines;Entity;Disciplines},{SNSF Portal Fields of Research;Entity;FieldsOfResearch}] *1[{Approved;1},{Requested;2}] *2[{grants;1},{funding;2}] in *4[{all;FundingInstrumentGaLevel1Id;00000000-0000-0000-0000-000000000000},{project;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{career;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{programme;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{infrastructure;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{science communication;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] funding schemes in between *5[2024--2005] and *6[2024--2005]",
        French:
          "*3[{Toutes les disciplines;MainDisciplineLevel2Id;00000000-0000-0000-0000-000000000000},{Sciences humaines et sociales;MainDisciplineLevel1Id;2FCD3F76-FE21-4BFB-9C3C-824472444C34},{Mathématiques, sciences naturelles et de l’ingénieur;MainDisciplineLevel1Id;735199F4-7EC1-4936-8282-9D5D0DCFF566},{Biologie et médecine;MainDisciplineLevel1Id;CEC00901-F216-42F6-93BF-8066B685BFAF},{Multi-domaines;MainDisciplineLevel1Id;E21089CE-D667-4257-B9ED-C5C14F171194}] *7[{Disciplines de mySNF;Entity;Disciplines},{Champs de recherche du Portail FNS;Entity;FieldsOfResearch}] *2[{Projets;1},{Moyens financiers;2}] *1[{approuvés;1},{demandés;2}] dans *4[{l’ensemble des instruments d’encouragement;FundingInstrumentGaLevel1Id;00000000-0000-0000-0000-000000000000},{l’encouragement de projets;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{l’encouragement de carrières;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{les programmes;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{le soutien des infrastructures;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{le soutien de la communication scientifique;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] entre *5[2024--2005] et *6[2024--2005]",
        Page: "Disciplines",
        German:
          "*3[{Alle Disziplinen;MainDisciplineLevel2Id;00000000-0000-0000-0000-000000000000},{Geistes- und Sozialwissenschaften;MainDisciplineLevel1Id;2FCD3F76-FE21-4BFB-9C3C-824472444C34},{Mathematik, Natur- und Ingenieurwissenschaften;MainDisciplineLevel1Id;735199F4-7EC1-4936-8282-9D5D0DCFF566},{Biologie und Medizin;MainDisciplineLevel1Id;CEC00901-F216-42F6-93BF-8066B685BFAF},{Bereichsübergreifend;MainDisciplineLevel1Id;E21089CE-D667-4257-B9ED-C5C14F171194}] *7[{mySNF Disziplinen;Entity;Disciplines},{SNF-Portal Forschungsfelder;Entity;FieldsOfResearch}] *1[{Bewilligte;1},{Beantragte;2}] *2[{Projekte;1},{finanzielle Mittel;2}] in *4[{allen Förderinstrumenten;FundingInstrumentGaLevel1Id;00000000-0000-0000-0000-000000000000},{der Projektförderung;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{der Karriereförderung;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{der Programmförderung;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{der Infrastrukturförderung;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{der Wissenschaftskommunikation;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] in den Jahren *5[2024--2005] bis *6[2024--2005]",
      },
      {
        Condition: "*1==1",
        Entity: "CoreFigures_ResultSentences",
        Page: "Disciplines",
        German:
          "Im Jahr *5 bewilligte der SNF insgesamt #ANZAHL# neue Projekte mit einem bewilligten Betrag von #SUMME# CHF in *3 in *4 (ohne NFS).",
        English:
          "The SNSF approved #ANZAHL# new grants with a total approved sum of CHF #SUMME# in *3 in *4 funding schemes (excluding NCCRs) in *5.",
        French:
          "En *5 le FNS a approuvé un total de #ANZAHL# nouveaux projets *3 dans *4 (sans PRN) pour un montant approuvé de #SUMME# CHF.",
      },
      {
        Condition: "*1==2",
        Entity: "CoreFigures_ResultSentences",
        Page: "Disciplines",
        German:
          "Im Jahr *5 evaluierte der SNF insgesamt #ANZAHL# Gesuche mit einem beantragten Betrag von #SUMME# CHF in *3 in *4 (ohne NFS).",
        English:
          "The SNSF evaluated #ANZAHL# applications with a total requested sum of CHF #SUMME# in *3 in *4 funding schemes (excluding NCCRs) in *5.",
        French:
          "En *5 le FNS a evalué un total de #ANZAHL# requêtes *3 dans *4 (sans PRN) pour un montant requis de #SUMME# CHF.",
      },
    ],
    Internationality: [
      {
        Entity: "CoreFigures_FilterSentences",
        English:
          "*2[{All Continents;CountryId;00000000-0000-0000-0000-000000000000},{Africa;ContinentCode;AF},{Asia;ContinentCode;AS},{North America;ContinentCode;NA},{South America;ContinentCode;SA},{Europe;ContinentCode;EU},{Oceania;ContinentCode;OC}] *1[{Applicants or project partners;Type;ApplicantsAndProjectPartners},{Destinations of mobility fellowships;Type;Fellowship},{Research collaborations by country;Type;Collaboration}] between *3[2024--2005] and *4[2024--2005]",
        French:
          "*2[{Monde entier;CountryId;00000000-0000-0000-0000-000000000000},{Afrique;ContinentCode;AF},{Asie;ContinentCode;AS},{Amérique du Nord;ContinentCode;NA},{Amérique du Sud;ContinentCode;SA},{Europe;ContinentCode;EU},{Océanie;ContinentCode;OC}] *1[{Requérant·es ou partenaires de projet;Type;ApplicantsAndProjectPartners},{Destinations des bourses de mobilité;Type;Fellowship},{Pays des collaborations de recherche;Type;Collaboration}] entre *3[2024--2005] et *4[2024--2005]",
        Page: "Internationality",
        German:
          "*2[{Alle Kontinenten;CountryId;00000000-0000-0000-0000-000000000000},{Afrika;ContinentCode;AF},{Asien;ContinentCode;AS},{Nordamerika;ContinentCode;NA},{Südamerika;ContinentCode;SA},{Europa;ContinentCode;EU},{Ozeanien;ContinentCode;OC}] *1[{Gesuchstellende oder Projektpartnerschaften;Type;ApplicantsAndProjectPartners},{Zielländer von Mobilitätsstipendien;Type;Fellowship},{Länder der Forschungszusammenarbeiten;Type;Collaboration}] in den Jahren *3[2024--2005] bis *4[2024--2005]",
      },
      {
        Condition: "*1==Fellowship",
        Entity: "CoreFigures_ResultSentences",
        Page: "Internationality",
        German:
          "In den Jahren *3 bis *4 lag die Anzahl Mobilitätsstipendien in *2 bei #ANZAHL#.",
        English:
          "Between *3 and *4 there was a total of #ANZAHL# mobility fellowships in *2.",
        French:
          "Entre *3 et *4 le nombre totale des bourses de mobilité en *2 est #ANZAHL#.",
      },
      {
        Condition: "*1==Collaboration",
        Entity: "CoreFigures_ResultSentences",
        Page: "Internationality",
        German:
          "In den Jahren *3 bis *4 lag die Anzahl Projektzusammenarbeiten in *2 bei #ANZAHL#.",
        English:
          "Between *3 and *4  there was a total of #ANZAHL# project collaborations in *2.",
        French:
          "Entre *3 et *4 le nombre totale des collaborations en *2 est #ANZAHL#.",
      },
      {
        Condition: "*1==ApplicantsAndProjectPartners",
        Entity: "CoreFigures_ResultSentences",
        Page: "Internationality",
        German:
          "In den Jahren *3 bis *4 lag die Anzahl der Gesuchstellenden oder Projektpartnerschaften in *2 bei #ANZAHL#.",
        TextSearch:
          "12-corefigures_resultsentences internationality *1==applicantsandprojectpartners between *3 and *4  there was a total of #anzahl# applicants or project partners in *2. in den jahren *3 bis *4 lag die anzahl der gesuchstellenden oder projektpartnerschaften in *2 bei #anzahl#. entre *3 et *4 le nombre totale des requérantes, requérants ou partenaires de projet en *2 est #anzahl#.",
        English:
          "Between *3 and *4  there was a total of #ANZAHL# applicants or project partners in *2.",
        French:
          "Entre *3 et *4 le nombre totale des requérantes, requérants ou partenaires de projet en *2 est #ANZAHL#.",
      },
    ],
    SupplementaryFunding: [
      {
        Entity: "CoreFigures_FilterSentences",
        English:
          "Approved *1[{grants;1},{funding;2}] in supplementary funding in between *2[2024--2005] and *3[2024--2005]",
        French:
          "*1[{Nombre de;1},{Moyens financiers dans des;2}] mesures complémentaires approuvés entre *2[2024--2005] et *3[2024--2005]",
        Page: "SupplementaryFunding",
        German:
          "Bewilligte *1[{Beiträge;1},{finanzielle Mittel;2}] in ergänzenden Massnahmen in den Jahren *2[2024--2005] bis *3[2024--2005]",
      },
      {
        Condition: "*1==1",
        Entity: "CoreFigures_ResultSentences",
        Page: "SupplementaryFunding",
        German:
          "Im Jahr *2 bewilligte der SNF #ANZAHL# Beiträge in ergänzenden Massnahmen mit insgesamt CHF #SUMME# (ohne NFS).",
        English:
          "In *2 the SNSF has approved #ANZAHL# supplementary measures for a total of CHF #SUMME# (excluding NCCRs).",
        French:
          "En *2 le FNS a approuvé #ANZAHL# subsides de mesures complémentaires pour un total de #SUMME# CHF (sans PRN).",
      },
      {
        Condition: "*1==2",
        Entity: "CoreFigures_ResultSentences",
        Page: "SupplementaryFunding",
        German:
          "Im Jahr *2 bewilligte der SNF #ANZAHL# Beiträge in ergänzenden Massnahmen mit insgesamt CHF #SUMME# (ohne NFS).",
        English:
          "In *2 the SNSF has approved #ANZAHL# supplementary measures for a total of CHF #SUMME# (excluding NCCRs).",
        French:
          "En *2 le FNS a approuvé #ANZAHL# subsides de mesures complémentaires pour un total de #SUMME# CHF (sans PRN).",
      },
    ],
    Kpi: [
      {
        Entity: "CoreFigures_FilterSentences",
        English:
          "The most important figures on the activities of the Swiss National Science Foundation (SNSF) over the past few years. The SNSF is funding *{KpiId; 3296D406-DCC9-CB9B-62AE-AEF99612BA6B} ongoing projects involving *{KpiId; BBBA5A6D-E965-21FF-4B73-E891B4AFE6E8} researchers. Since 1 January 2025, *{KpiId; D3C79F30-53BC-63BB-AC42-E359260AC740} research proposals have been evaluated.",
        French:
          "Chiffres les plus importants sur les activités menées par le FNS au cours des dernières années. Le FNS encourage actuellement *{KpiId; 3296D406-DCC9-CB9B-62AE-AEF99612BA6B} projets impliquant *{KpiId; BBBA5A6D-E965-21FF-4B73-E891B4AFE6E8} chercheuses et chercheurs. Depuis le 1er janvier 2025, le FNS a évalué *{KpiId; D3C79F30-53BC-63BB-AC42-E359260AC740} requêtes.",
        Page: "Kpi",
        German:
          "Die wichtigsten Zahlen zu den Aktivitäten des Schweizerischen Nationalfonds (SNF) über die letzten Jahre. Der SNF fördert *{KpiId; 3296D406-DCC9-CB9B-62AE-AEF99612BA6B} laufende Projekte mit *{KpiId; BBBA5A6D-E965-21FF-4B73-E891B4AFE6E8} Forschenden. Seit dem 1. Januar 2025 sind *{KpiId; D3C79F30-53BC-63BB-AC42-E359260AC740} Gesuche evaluiert worden.",
      },
    ],
  };

  if (filterSentences[sentenceEntity]) {
    return Promise.resolve(filterSentences[sentenceEntity]);
  }

  console.error(`could not find filter sentence for ${sentenceEntity}!`);
}

const langFilterSentenceKey = {
  fr: "French",
  en: "English",
  de: "German",
};

export function fetchFullKeyFiguresData(
  selectedLanguage,
  cfPageData,
  routeQuery = {},
  singleYear = false,
) {
  let cfSelectedParts = {};
  let cfParseSentenceResult = {};
  let cfSolrSentences = [];

  return querySentences(cfPageData.sentenceEntity, "search", singleYear).then(
    (solrSentences) => {
      cfSolrSentences = solrSentences;

      if (solrSentences.length <= 0) {
        return new Promise.resolve({
          cfSolrSentences: cfSolrSentences,
        });
      }

      let filterSentence = _.find(solrSentences, {
        Entity: "CoreFigures_FilterSentences",
      })[langFilterSentenceKey[selectedLanguage]];

      // TODO: hardcoded test filter sentence data
      if (cfPageData.solrEntity === "Demographics") {
        if (!filterSentence.includes("Gender;x}") && selectedLanguage === "en") {
          filterSentence = filterSentence.replace(
            "{male;Gender;m},",
            "{male;Gender;m},{non-binary;Gender;x},",
          );
          // filterSentence = filterSentence.replace('*1[{All;Gender;Total},{Female;Gender;f},{Male;Gender;m}]', '*1[{All;Gender;Total},{Female;Gender;f},{Male;Gender;m},{Comparison Female and Male;Gender;Total}]')
          // filterSentence = filterSentence.replace('*1[{Alle;Gender;Total}, {Frauen;Gender;f}, {Männer;Gender;m}]', '*1[{Alle;Gender;Total}, {Frauen;Gender;f}, {Männer;Gender;m}, {Vergleich Frauen und Männer;Gender;Total}]')
          // filterSentence = filterSentence.replace('*1[{Total;Gender;Total},{Femmes;Gender;f},{Hommes;Gender;m}]', '*1[{Total;Gender;Total},{Femmes;Gender;f},{Hommes;Gender;m},{Comparaison Femmes et Hommes;Gender;Total}]')
        }
      }

      if (cfPageData.solrEntity === "FundingInstruments") {
        if (!filterSentence.includes("Gender;x}") && selectedLanguage === "en") {
          filterSentence = filterSentence.replace(
            "{men;ResponsibleApplicantGender;m}",
            "{men;ResponsibleApplicantGender;m},{non-binary persons;ResponsibleApplicantGender;x}",
          );
        }
        // if (!filterSentence.includes("Gender;x}") && selectedLanguage === "en") {
        //   filterSentence = filterSentence.replace(
        //     "{male;Gender;m},",
        //     "{male;Gender;m},{non-binary;Gender;x},",
        //   );
        // filterSentence = filterSentence.replace('*1[{All;Gender;Total},{Female;Gender;f},{Male;Gender;m}]', '*1[{All;Gender;Total},{Female;Gender;f},{Male;Gender;m},{Comparison Female and Male;Gender;Total}]')
        // filterSentence = filterSentence.replace('*1[{Alle;Gender;Total}, {Frauen;Gender;f}, {Männer;Gender;m}]', '*1[{Alle;Gender;Total}, {Frauen;Gender;f}, {Männer;Gender;m}, {Vergleich Frauen und Männer;Gender;Total}]')
        // filterSentence = filterSentence.replace('*1[{Total;Gender;Total},{Femmes;Gender;f},{Hommes;Gender;m}]', '*1[{Total;Gender;Total},{Femmes;Gender;f},{Hommes;Gender;m},{Comparaison Femmes et Hommes;Gender;Total}]')
      }

      // if (cfPageData.solrEntity === "FundingInstruments") {
      //   filterSentence = "*4[{allen Förderungsinstrumenten;FundingInstrumentReportingId;00000000-0000-0000-0000-000000000000},{der Projektförderung;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{der Karriereförderung;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{der Programmförderung;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{der Infrastrukturförderung;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{der Wissenschaftskommunikation;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] *1[{Bewilligte;1},{Beantragte;2}] *2[{Projekte;1},{finanzielle Mittel;2}] von *3[{allen Gesuchstellenden;ResponsibleApplicantGender;Total},{Frauen;ResponsibleApplicantGender;f},{Männern;ResponsibleApplicantGender;m}] im Jahr *5[2021--2005]";
      // }

      cfParseSentenceResult = parseSentence(
        filterSentence,
        cfPageData.solrLevelAttribute,
        cfPageData.solrAttributeYear,
      );

      // if (cfPageData.solrEntity === "FundingInstruments") {
      //   cfParseSentenceResult.levelInfo.highlight = true;
      // }

      _.forEach(cfParseSentenceResult.parsedEntries, (e) => {
        const routeIndexKey = e.id.replace("*", "s");
        e.selectedIndex = routeQuery[routeIndexKey] || 0;

        if (!singleYear) {
          // set a date range for sentences with multiple call decision years
          if (cfPageData.entityUrl === "internationality" && e.id === "*3") {
            // selected 5th year entry for internationality date range
            e.selectedIndex = routeQuery[routeIndexKey] || 6;
          }
          if (cfPageData.entityUrl === "fundingInstruments" && e.id === "*5") {
            e.selectedIndex = routeQuery[routeIndexKey] || 6;
          }
          if (cfPageData.entityUrl === "researchInstitutions" && e.id === "*5") {
            e.selectedIndex = routeQuery[routeIndexKey] || 6;
          }
          if (cfPageData.entityUrl === "disciplines" && e.id === "*5") {
            e.selectedIndex = routeQuery[routeIndexKey] || 6;
          }
          if (cfPageData.entityUrl === "supplementalFunding" && e.id === "*2") {
            e.selectedIndex = routeQuery[routeIndexKey] || 6;
          }
        }

        e.selectedIndex = parseInt(e.selectedIndex);
        if (e.selectedIndex >= e.selectionValues.length) {
          e.selectedIndex = 0;
        }
      });

      cfSelectedParts = createSelectedPartsInitial(cfParseSentenceResult.parsedEntries);

      return fetchKeyFiguresData(
        selectedLanguage,
        cfPageData,
        cfParseSentenceResult,
        cfSelectedParts,
        cfSolrSentences,
      );
    },
  );
}

export function fetchKeyFiguresData(
  selectedLanguage,
  cfPageData,
  cfParseSentenceResult,
  cfSelectedParts,
  cfSolrSentences,
) {
  let cfPictureData = null;
  let cfResultSentence = "";
  let cfSolrQuery = "";

  function createResult() {
    return {
      cfSelectedParts: cfSelectedParts,
      cfPictureData: cfPictureData,
      cfParseSentenceResult: cfParseSentenceResult,
      cfSolrSentences: cfSolrSentences,
      cfResultSentence: cfResultSentence,
      cfSolrQuery: cfSolrQuery,
    };
  }

  const ignoreParts = _.chain(cfSelectedParts)
    .values()
    .filter((p) => {
      return !p.solrAttribute;
    })
    .map((p) => {
      return p.id;
    })
    .value();

  if (ignoreParts.length > 2) {
    console.warn("mehr als 2 `ignoreParts` !!!");
  }

  // always fetch country data directly for internationality page
  if (cfPageData.entityUrl === "internationality") {
    cfSelectedParts["*2"].solrPartOverride =
      "(" +
      _.join(
        _.map(CONTINENTS, (cc) => {
          return `ContinentCode:${cc}`;
        }),
        "+OR+",
      ) +
      ")";
  }

  if (cfPageData.entityUrl === "demographics") {
    if (cfSelectedParts["*1"].index === 4) {
      ignoreParts.push("*1");
    }
  }

  let entity = cfPageData.solrEntity;
  let selectedParts = _.cloneDeep(cfSelectedParts);

  if (cfPageData.entityUrl === "disciplines") {
    ignoreParts.push("*7");
    entity = cfSelectedParts?.["*7"]?.solrValue || "Disciplines";
  }

  if (
    cfPageData.entityUrl === "disciplines" &&
    cfSelectedParts["*7"]?.solrValue === "FieldsOfResearch" &&
    cfSelectedParts["*3"].index === 0
  ) {
    selectedParts["*3"].solrAttribute = "MainFieldOfResearchLevelAId";
  }

  cfSolrQuery = buildQuery(`Entity:${entity}`, selectedParts, ignoreParts);

  return axios({
    url: cfSolrQuery,
  }).then((response) => {
    if (cfPageData.entityUrl === "demographics") {
      const genders = _.uniq(_.map(response.data.response.docs, "Gender"));

      let dataObject = {};
      genders.forEach((g) => {
        dataObject[g] = {};
      });

      _.forEach(_.range(20, 76), (n) => {
        genders.forEach((g) => {
          dataObject[g][n] = 0;
        });
      });
      genders.forEach((g) => {
        dataObject[g][76] = 0;
      });

      // merge <20 and >75
      _.forEach(response.data.response.docs, (d) => {
        if (d.Age <= 20) {
          dataObject[d.Gender][20] = d.NumberOfPersons + (dataObject[20] || 0);
        } else if (d.Age >= 75) {
          dataObject[d.Gender][75] = d.NumberOfPersons + (dataObject[75] || 0);
        } else {
          dataObject[d.Gender][d.Age] = d.NumberOfPersons;
        }
      });

      // try to add total value
      // genders.forEach((g) => {
      //   dataObject[g][76] = 4500;
      // });

      const dataArray = _.map(_.keys(dataObject[genders[0]]), (key) => {
        const result = {
          name: key,
          value: dataObject[genders[0]][key],
        };
        genders.forEach((g) => {
          result[g] = dataObject[g][key];
        });
        return result;
      });

      if (response.data.response.docs.length <= 0) {
        cfPictureData = [];
      } else {
        cfPictureData = _.orderBy(dataArray, "name", "desc");
      }

      cfResultSentence = createResultSentenceMedian(
        cfSolrSentences,
        cfSelectedParts,
        cfPictureData,
        selectedLanguage,
      );

      return createResult();
    } else if (cfPageData.entityUrl === "internationality") {
      let parsedSolrResponse = createDataFromResponse(
        response.data,
        cfParseSentenceResult.levelInfo,
      );

      // remove unused "Total" parts from data
      parsedSolrResponse = _.filter(parsedSolrResponse, (e) => {
        return e.Country_De !== "Total";
      });

      // convert Number from string to number
      parsedSolrResponse = _.map(parsedSolrResponse, (e) => {
        e.Number = parseInt(e.Number, 10);
        return e;
      });

      const pictureDataWithDoubleCountries = _.reduce(
        parsedSolrResponse,
        (result, country) => {
          if (!_.find(result, { CountryIsoCode: country.CountryIsoCode })) {
            country.Number = _.sumBy(
              _.filter(parsedSolrResponse, {
                CountryIsoCode: country.CountryIsoCode,
              }),
              "Number",
            );
            return result.concat(country);
          }
          return result;
        },
        [],
      );

      const langSuffix = langSuffixTable[selectedLanguage];
      cfPictureData = _.map(pictureDataWithDoubleCountries, (c) => {
        c.label = c["Country" + langSuffix];
        c.labelContinent = c["Continent" + langSuffix];
        c.count = parseInt(c.Number, 10);

        if (c.CountryIsoCode === "KV") {
          // replace country code for Kosovo
          c.CountryIsoCode = "XK";
        }
        return c;
      });

      // calculate total number based on selected continent
      const selectedContinent = cfSelectedParts["*2"].solrValue;
      let totalNumber = _.sumBy(cfPictureData, "count");
      if (_.includes(CONTINENTS, selectedContinent)) {
        totalNumber = _.sumBy(
          _.filter(cfPictureData, { ContinentCode: selectedContinent }),
          "count",
        );
      }

      cfResultSentence = createResultSentence(
        cfSolrSentences,
        cfSelectedParts,
        totalNumber,
        0,
        selectedLanguage,
        cfPageData.solrEntity,
      );

      return createResult();
    } else {
      const levelInfo = _.cloneDeep(cfParseSentenceResult.levelInfo);

      if (selectedParts["*7"]?.solrValue === "FieldsOfResearch") {
        levelInfo.level2Field = "MainFieldOfResearchLevelA";
      }

      const parsedSolrResponse = createDataFromResponse(response.data, levelInfo);

      cfPictureData = createPictureData(
        parsedSolrResponse,
        levelInfo,
        selectedParts,
        selectedLanguage,
      );

      const numbers = getResultNumbers(cfSelectedParts, cfPictureData);
      cfResultSentence = createResultSentence(
        cfSolrSentences,
        selectedParts,
        numbers.totalNumber,
        numbers.totalMoneySum,
        selectedLanguage,
        cfPageData.solrEntity,
      );

      return createResult();
    }
  });
}

export function loadKpiSentence(selectedLanguage, addTags = true) {
  return axios
    .all([querySentences("Kpi"), axios.get("/solr/search/select/?q=Entity%3AKpi")])
    .then(([r1, r2]) => {
      let kpiSentence = r1[0][langFilterSentenceKey[selectedLanguage]];
      //log.debug(this.kpiSentence);

      const kpiData = r2.data.response.docs;
      //log.debug(kpiData);

      const re = /\*{KpiId; (.*?)}/g;
      const results = getAllMatches(kpiSentence, re);
      //log.debug(results);

      _.forEach(results, (kpiResult) => {
        const kpiId = kpiResult.groups[0];
        const value = _.find(kpiData, { id: kpiId }).Value;
        if (addTags) {
          kpiSentence = kpiSentence.replace(
            kpiResult.match,
            `<span class="cf-result-number">${numberToStringFormat(
              value,
              selectedLanguage,
            )}</span>`,
          );
        } else {
          kpiSentence = kpiSentence.replace(
            kpiResult.match,
            `${numberToStringFormat(value, selectedLanguage)}`,
          );
        }
      });

      return kpiSentence;
    });
}

export function loadDataStories(lang, tagIds, limit, ignoreStory) {
  const queryStoryIds = function () {
    const fields = ["DataStoryName", "PublicationDate", "Languages", "Language", "id"];

    const selectedLanguage = lang;
    let query = buildEntityQuery(`Entity:DataStories`, limit ? limit * 3 + 3 : 150);

    if (tagIds && tagIds.length > 0) {
      const solrTagPart = _.join(
        _.map(tagIds, (t) => {
          return `Tags:*${t}*`;
        }),
        "+OR+",
      );
      query += `+AND+(${solrTagPart})`;
    }

    query +=
      "&sort=PublicationDate%20desc,DataStoryName%20asc&fl=" + _.join(fields, ",");

    // ignore stories with publication date in future
    query += "&fq=PublicationDate:[2020-01-01T00:00:00Z%20TO%20NOW]";

    return axios.get(query).then((response) => {
      let distinctStories = _.reduce(
        response.data.response.docs,
        function (stories, story) {
          if (
            stories.length &&
            stories[stories.length - 1].DataStoryName === story.DataStoryName
          ) {
            // check language preference and priority
            // TODO: language priority en,fr,de
            if (story.Language === _.upperCase(selectedLanguage)) {
              stories[stories.length - 1] = story;
            }
          } else {
            stories.push(story);
          }
          return stories;
        },
        [],
      );

      if (ignoreStory) {
        distinctStories = _.reject(distinctStories, {
          DataStoryName: ignoreStory,
        });
      }

      return _.map(distinctStories, (s) => {
        return s.id;
      });
    });
  };

  return queryStoryIds().then((storyIds) => {
    if (storyIds.length === 0) {
      return [];
    }

    const solrFields = [
      "Author",
      "AuthorURL",
      "Category",
      "Created",
      "DOI",
      "DataStoryCategory",
      "DataStoryName",
      "Entity",
      "GitHub_URL",
      "Language",
      "Languages",
      "Modified",
      "Name",
      "PublicationDate",
      "ShortDescription_De",
      "ShortDescription_En",
      "ShortDescription_Fr",
      "Tags",
      "ThumbnailExtension",
      "Title_De",
      "Title_En",
      "Title_Fr",
      "TopStory",
      "id",
      "ThumbnailLink",
    ];

    const query =
      buildEntityQuery(
        `Entity:DataStories+AND+(` +
          _.join(
            _.map(storyIds, (n) => {
              return `id:${n}`;
            }),
            "+OR+",
          ),
        limit ? limit : 100,
      ) +
      ")&sort=PublicationDate%20desc&fl=" +
      _.join(solrFields, ",");

    return axios.get(query).then((response) => {
      //this.stories = response.data.response.docs;
      return _.map(response.data.response.docs, (s, index) => {
        s.title =
          objectAttributeInLanguage(s, "Title", lang) ||
          s.Title_En ||
          s.Title_Fr ||
          s.Title_De;
        s.shortDescription = _.truncate(
          objectAttributeInLanguage(s, "ShortDescription", lang) ||
            s.ShortDescription_En ||
            s.ShortDescription_Fr ||
            s.ShortDescription_De,
          {
            length: 260,
          },
        );
        return s;
      });
    });
  });
}
