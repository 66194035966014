<template>
  <div>
    <div class="multi-year-chart-section">
      <div ref="chartDiv" id="multi-year-chart" :style="chartStyleOverride" />
    </div>
  </div>
</template>

<script setup>
// Import stuff
import { computed, defineProps, onMounted, onUnmounted, ref, watch } from "vue";
import { createChart } from "../../helpers/multiYearChartHelpers.js";
import * as _ from "lodash";
import * as log from "loglevel";

import { useStore } from "vuex-composition-helpers";
import { nanoEmitter } from "@/events";

const store = useStore();
const selectedLanguage = computed(() => store.getters["selectedLanguage"]);

const chartDiv = ref();
const chartGraph = ref(null);

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  minYear: {
    type: Number,
    default: 2005,
  },
  maxYear: {
    type: Number,
    default: 2023,
  },
  stacked: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: "column",
  },
  colors: {
    type: String,
    default: "snf",
  },
  yLabel: {
    type: String,
    default: "Projekte",
  },
  filename: {
    type: String,
  },
});

const chartLegendHeight = ref(0);

function exportChart() {
  if (chartGraph.value !== null) {
    chartGraph.value.exporting.filePrefix = props.filename;
    chartGraph.value.exporting.export("png");
  }
}

let exportGraphUnbind = null;

onMounted(() => {
  log.debug("MultiYearChart mounted");
  _.defer(() => {
    recreateChart();
  });

  exportGraphUnbind = nanoEmitter.on("exportGraph", exportChart);
});

onUnmounted(() => {
  if (chartGraph.value !== null) {
    chartGraph.value.dispose();
    chartGraph.value = null;
  }

  if (exportGraphUnbind) {
    exportGraphUnbind();
    exportGraphUnbind = null;
  }
});

const recreateChart = () => {
  log.debug("MultiYearChart recreateChart");
  if (chartGraph.value !== null) {
    chartGraph.value.dispose();
    chartGraph.value = null;
  }
  chartGraph.value = createChart(
    {
      el: chartDiv.value,
      chart: chartGraph.value,
      language: selectedLanguage.value,
      ...props,
    },
    onReadyChart,
  );
};
watch(() => ({ ...props }), recreateChart);

function onReadyChart() {
  log.debug("MultiYearChart onReadyChart");
  if (chartGraph.value) {
    // legend is max 300px high
    chartLegendHeight.value = Math.min(chartGraph.value.legend.contentHeight, 300);
  } else {
    chartLegendHeight.value = 0;
  }
}

const chartStyleOverride = computed(() => {
  const height = 500 + chartLegendHeight.value;
  const heightPixel = `${height}px`;
  return {
    height: heightPixel,
    "min-height": heightPixel,
    "max-height": heightPixel,
  };
});
</script>

<style scoped>
.multi-year-chart-section {
  margin-left: 25px;
  margin-right: 25px;
  width: calc(100vw - 50px);
  max-width: 1280px;
  position: relative;
}

@media (max-width: 768px) {
  .multi-year-chart-section {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

#multi-year-chart {
  width: 100%;
  height: 540px;
  min-height: 540px;
  max-height: 540px;
}
</style>
