import * as _ from "lodash";
import { mapGetters } from "vuex";
import {
  numberToStringFormat,
  numberToStringPandemic,
  replaceWithSpanHighlight,
} from "@/utils";

export function attributeInLanguage(attr, lang) {
  return `${attr}_${_.capitalize(lang)}`;
}

export function objectAttributeInLanguage(data, attr, lang) {
  return data[attributeInLanguage(attr, lang)];
}

export function cmsTranslationByKey(data, key, lang) {
  if (
    data?.[key]?.[lang]
  ) {
    return data[key][lang];
  }
  return `XX ${key}`;
}

export const cmsTranslationMixin = {
  computed: {
    ...mapGetters(["cmsTranslationData", "selectedLanguage"]),
  },
  methods: {
    cmsTranslationByKey(key) {
      if (
        this.cmsTranslationData &&
        this.cmsTranslationData[key] &&
        this.cmsTranslationData[key][this.selectedLanguage]
      ) {
        return this.cmsTranslationData[key][this.selectedLanguage];
      }
      return `XX ${key}`;
    },
    attributeInLanguage(
      attribute,
      postfix = "",
      withLanguage = true,
      overrideLanguage = null,
    ) {
      if (!overrideLanguage) {
        overrideLanguage = this.selectedLanguage;
      }

      if (withLanguage) {
        return `${attribute}_${_.capitalize(overrideLanguage)}${postfix}`;
      }
      return `${attribute}${postfix}`;
    },
    objectAttributeInLanguage(data, attribute, postfix) {
      let langValue = data[this.attributeInLanguage(attribute, postfix)];
      if (langValue) {
        return langValue;
      }

      // try to get the same attribute without language CF-870, CF-821
      langValue = data[this.attributeInLanguage(attribute, postfix, false)];
      if (langValue) {
        return langValue;
      }

      // try to get the attribute in English DSS-1867
      langValue = data[this.attributeInLanguage(attribute, postfix, true, "en")];
      if (langValue) {
        return langValue;
      }

      if (postfix) {
        return this.objectAttributeInLanguage(data, attribute, "");
      }
      return undefined;
    },
    numberToStringFormat(num) {
      return numberToStringFormat(num, this.selectedLanguage);
    },
    numberToStringPandemic(num) {
      return numberToStringPandemic(num, this.selectedLanguage);
    },
    clamp(number, lower, upper) {
      return _.clamp(number, lower, upper);
    },
    readOnLanguagesText(languages) {
      if (languages === "de") {
        return this.cmsTranslationByKey("LanguageDe");
      } else if (languages === "fr") {
        return this.cmsTranslationByKey("LanguageFr");
      } else if (languages === "en") {
        return this.cmsTranslationByKey("LanguageEn");
      } else if (languages === "en;de") {
        return this.cmsTranslationByKey("LanguageEnDe");
      } else if (languages === "de;fr") {
        return this.cmsTranslationByKey("LanguageFrDe");
      } else if (languages === "en;fr") {
        return this.cmsTranslationByKey("LanguageEnFr");
      }
      return "";
    },
    joinSplitMvfField(field, joinSeparator = ", ", splitSeparator = ";") {
      return _.join(_.split(field, splitSeparator), joinSeparator);
    },
    getSnfUrlInLanguage() {
      if (this.selectedLanguage === "en") {
        return "http://www.snsf.ch";
      } else if (this.selectedLanguage === "fr") {
        return "http://www.fns.ch";
      }
      return "http://www.snf.ch";
    },
    getSnfDataPortalTitle() {
      if (this.selectedLanguage === "en") {
        return "Data Portal";
      } else if (this.selectedLanguage === "fr") {
        return "Portail de données";
      }
      return "Datenportal";
    },
  },
};

export const highlighterMixin = {
  methods: {
    highlightString(input, substr, cssAtributes = 'style="background: #cbe0eb;"') {
      if (substr && _.trim(substr).length >= 3) {
        return replaceWithSpanHighlight(input, substr, cssAtributes);
      }
      return input;
    },
  },
};
