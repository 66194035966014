<template>
  <div class="grant-registry-index" ref="container" v-if="cmsData">
    <section class="grant-registry-section title-section">
      <div class="grant-registry-header">
        <h1 class="grant-registry-title">
          {{ cmsTranslationByKey("GrantRegistry") }}
        </h1>

        <div class="grant-registry-search">
          <div class="grant-registry-search-form">
            <div class="grant-registry-search-input">
              <input
                class="input search-input"
                type="text"
                @input="debounceSearchInput"
                v-model="searchInputValue"
                :placeholder="cmsTranslationByKey('GrantRegistrySearchSuggestion')"
                ref="grantRegistrySearchInputHtml"
              />
              <button
                class="grant-registry-search-button"
                v-show="searchInputValue"
                @click="resetSearch()"
              >
                <img
                  src="/img/reset-search.svg"
                  class="svg-blue-font-filter"
                  alt="reset-search"
                />
              </button>
            </div>
            <div class="grant-registry-help-div">
              <button
                class="grant-registry-help-button"
                @click="toggleHelp()"
                @mouseenter="toggleHelp(true)"
              >
                <img src="/img/help.svg" alt="help-search" />
              </button>
            </div>
          </div>

          <div class="grant-registry-search-help" v-show="showHelp">
            <div class="search-help-close" @click="toggleHelp()">
              <img
                src="/img/reset-search.svg"
                class="svg-blue-font-filter"
                alt="reset-search"
              />
            </div>
            <div>
              <div v-html="searchHelpTextHtml"></div>
              <!--              <div class="search-help-title">Search Queries Explained</div>-->
              <!--              <div class="search-help-text">-->
              <!--                You can use the following operators to search for grants:-->
              <!--                <ul>-->
              <!--                  <li>-->
              <!--                    <span class="search-help-highlight">AND</span>-->
              <!--                    -> search for results related to X and Y, the AND is implicit:-->
              <!--                    <span class="search-help-highlight">jobs AND gates</span>-->
              <!--                    the same as-->
              <!--                    <span class="search-help-highlight">jobs gates</span>-->
              <!--                  </li>-->
              <!--                  <li>-->
              <!--                    <span class="search-help-highlight">OR</span>-->
              <!--                    -> search for results related to X or Y (`|` will also work):-->
              <!--                    <span class="search-help-highlight">jobs OR gates</span>-->
              <!--                    the same as-->
              <!--                    <span class="search-help-highlight">jobs | gates</span>-->
              <!--                  </li>-->
              <!--                  <li>-->
              <!--                    <span class="search-help-highlight">NOT</span>-->
              <!--                    -> search for results related to X and Y (`-` will also work):-->
              <!--                    <span class="search-help-highlight">jobs NOT gates</span>-->
              <!--                    the same as-->
              <!--                    <span class="search-help-highlight">jobs -gates</span>-->
              <!--                  </li>-->
              <!--                </ul>-->

              <!--                You can group multiple terms together with parentheses:-->
              <!--                <br />-->
              <!--                <span class="search-help-highlight">(ipad OR iphone) AND apple</span>-->
              <!--              </div>-->
            </div>
          </div>
        </div>

        <div class="right-space"></div>
      </div>
    </section>

    <section class="grant-registry-section toggle-filter-mobile-section">
      <div class="toggle-filter-mobile">
        <button
          class="snf-button snf-button--outline"
          @click="setGrantShowFilterMobile(true)"
        >
          <img src="/img/filter.svg" alt="filter" />
          <span>
            {{ cmsTranslationByKey("FilterSearchResults") }}
          </span>

          <span class="badge" v-show="grantCheckedFilterValues.length">
            {{ grantCheckedFilterValues.length }}
          </span>
        </button>
      </div>
    </section>

    <section class="grant-registry-section results-section">
      <div
        id="popout-filter"
        class="popout-filter"
        :class="{
          'popout-filter--visible': popoutFilterVisible,
        }"
        v-on-click-outside="clickOutsidePopoutFilter"
      >
        <div class="popout-filter__header" v-if="popoutFilterEntityEntry">
          <img
            class="svg-blue-font-filter"
            src="/img/arrow-left.svg"
            @click="closePopoutFilter(true)"
          />
          <div class="popout-filter__header__text">
            <div>
              {{ cmsTranslationByKey(popoutFilterEntityEntry.titleKey) }}
            </div>
            <div
              class="badge grant-show-mobile"
              v-show="popoutGrantCheckedFilterValues.length"
            >
              {{ popoutGrantCheckedFilterValues.length }}
            </div>
          </div>

          <img
            class="grant-show-mobile"
            src="/img/close.svg"
            @click="closePopoutFilter(false)"
          />
        </div>
        <div class="popout-filter__content">
          <GrantSearchFilterEntry
            v-if="popoutFilterEntityId"
            :filter-entity-id="popoutFilterEntityId"
            :outer-solr-response="solrFacetResponse"
            :outer-solr-query-q="solrGrantQueryQ"
            :show-search-filter-input="true"
            :as-popout="true"
            :initial-filter-data="popoutFilterData"
            :initial-collapsed="false"
            :show-loader="showLoader"
            @toggle-search-filter="toggleSearchFilter"
            @toggle-date-filter="toggleDateFilter"
            @reset-entity-filters="resetEntityFilters"
          ></GrantSearchFilterEntry>
        </div>

        <div class="popout-filter__buttons">
          <button
            class="snf-button snf-button--outline"
            @click="closePopoutFilter(false)"
          >
            {{ cmsTranslationByKey("Close") }}
          </button>
        </div>
      </div>

      <div
        class="filter-section"
        :class="{
          'filter-section--sticky-fixed': filterSectionStickyFixed,
          'filter-section--mobile-visible': grantShowFilterMobile,
        }"
        ref="filterSection"
        v-if="isMounted"
      >
        <div class="filter-section__title">
          <h4>
            {{ cmsTranslationByKey("FilterSearchResults") }}
          </h4>

          <img
            class="filter-section__close"
            src="/img/close.svg"
            @click="setGrantShowFilterMobile(false)"
          />
        </div>

        <!-- <GrantSearchFilterResetButton @reset-filters="resetFilters()"></GrantSearchFilterResetButton> -->

        <div
          class="filter-entry"
          v-for="filterEntry in filterEntries"
          :key="filterEntry"
        >
          <GrantSearchFilterEntry
            :outer-solr-response="solrFacetResponse"
            :filter-entity-id="filterEntry"
            :show-loader="showLoader"
            @toggle-search-filter="toggleSearchFilter"
            @toggle-date-filter="toggleDateFilter"
            @filter-show-all="filterShowAll"
          ></GrantSearchFilterEntry>
        </div>
      </div>

      <div
        class="search-results-section"
        :class="{ 'mobile-fixed': grantShowFilterMobile }"
      >
        <ActiveGrantFilterList
          :key="renderActiveFiltersCount"
          @toggle-search-filter="toggleSearchFilter"
          @toggle-date-filter="toggleDateFilter"
          @reset-filters="resetFilters()"
        ></ActiveGrantFilterList>

        <Loader class="search-results-loader" v-if="showLoader"></Loader>
        <div v-else>
          <div class="grants-number" v-if="solrGrantResponse">
            {{ numberToStringPandemic(rowsTotal) }}
            {{ cmsTranslationByKey("NumGrants") }}
          </div>

          <GrantSearchEntry
            class="grant-entry"
            v-for="(grant, index) in parsedGrants"
            :key="grant.GrantNumber"
            :grant="grant"
            :index="index"
            :search-input="searchInput"
          ></GrantSearchEntry>

          <section class="section-grant-actions" v-if="parsedGrants.length > 0">
            <div class="pagination">
              <div
                class="pagination-element"
                @click="paginationClickElement(paginationData.currentPage - 1)"
                :disabled="paginationData.currentPage <= 1"
              >
                <img src="/img/arrow-left.svg" alt="previous" />
              </div>

              <div
                class="pagination-element"
                :key="index"
                :class="{
                  'pagination-selected': paginationData.currentPage === item,
                }"
                v-for="(item, index) in paginationCalcElements()"
                @click="paginationClickElement(item)"
              >
                {{ item }}
              </div>

              <div
                class="pagination-element"
                @click="paginationClickElement(paginationData.currentPage + 1)"
                :disabled="paginationData.currentPage >= paginationData.lastPage"
              >
                <img src="/img/arrow-right.svg" alt="next" />
              </div>
            </div>

            <div class="results-download-section">
              <button
                class="results-download-button"
                @click="downloadModalVisible = true"
              >
                <img src="/img/download.svg" />
                {{ cmsTranslationByKey("DownloadResults") }}
              </button>
            </div>
          </section>
        </div>
      </div>
    </section>

    <section
      id="download-modal"
      class="download-modal"
      :class="{ 'download-modal--visible': downloadModalVisible }"
    >
      <div class="modal-backdrop"></div>
      <div class="modal-body">
        <div class="modal-header">
          <div>
            {{ cmsTranslationByKey("DownloadResults") }}
          </div>

          <img class="" src="/img/close.svg" @click="downloadModalVisible = false" />
        </div>
        <div class="modal-content">
          <div v-if="downloadState === 'init'">
            <p
              v-if="rowsTotal > downloadRowsLimit"
              v-html="downloadResultsTextMany"
            ></p>
            <p v-else v-html="downloadResultsText"></p>
            <h6>{{ cmsTranslationByKey("DownloadFileFormat") }}</h6>

            <div class="radio-buttons">
              <div class="radio-button">
                <input
                  type="radio"
                  id="csv"
                  name="csv"
                  value="csv"
                  v-model="downloadFormat"
                />
                <label for="csv">.CSV</label>
              </div>

              <div class="radio-button">
                <input
                  type="radio"
                  id="xls"
                  name="xls"
                  value="xls"
                  v-model="downloadFormat"
                />
                <label for="xls">.XLSX</label>
              </div>
            </div>
          </div>
          <div v-else>
            <p>
              You are downloading
              <b>
                {{ numberToStringPandemic(clamp(rowsTotal, downloadRowsLimit)) }}
              </b>
              grants.
            </p>
            <div class="download-percent-bar">
              <div
                class="download-percent-bar--full"
                :style="{ width: downloadPercent + '%' }"
              ></div>
            </div>
          </div>
        </div>
        <div class="modal-buttons">
          <button
            class="snf-button snf-button--outline modal-cancel"
            @click="cancelDownload()"
          >
            {{ cmsTranslationByKey("Cancel") }}
          </button>
          <button
            class="snf-button modal-ok"
            @click="startDownload()"
            :disabled="downloadState === 'loading'"
          >
            {{ cmsTranslationByKey("StartDownload") }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as log from "loglevel";
import axios from "axios";
import XLSX from "xlsx";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { cmsTranslationMixin } from "@/mixins";
import Loader from "@/components/Loader";
import GrantSearchEntry from "@/components/GrantSearchEntry";
import { vOnClickOutside } from "@vueuse/components";
import {
  createSolrFirstDateFromYear,
  createSolrGetRequestUrl,
  createSolrLastDateFromYear,
  createTextSearchQuery,
  FILTER_ENTRIES_LIST,
  GRANT_FILTER_MAPPING,
  groupGrantQueryLevels,
  makeSolrPostRequest,
  OUTPUT_DATA_TYPES,
  splitTrimWords,
} from "@/utils";
import GrantSearchFilterEntry from "@/components/GrantSearchFilterEntry";
import ActiveGrantFilterList from "@/components/ActiveGrantFilterList";
import PrismicDOM from "prismic-dom/src";
import { nanoEmitter } from "@/events";

const startDateField = "EffectiveGrantStartDate";
const endDateField = "EffectiveGrantEndDate";

let changeQueryParamUnbind = null;

export default {
  name: "GrantRegistry",
  mixins: [cmsTranslationMixin],
  components: {
    // GrantSearchFilterResetButton,
    ActiveGrantFilterList,
    Loader,
    GrantSearchEntry,
    GrantSearchFilterEntry,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  metaInfo: function () {
    if (this.cmsTranslationData) {
      return {
        title: this.cmsTranslationByKey("TitleGrantRegistry"),
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: this.cmsTranslationByKey("TitleGrantRegistry"),
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: this.cmsTranslationByKey("GrantRegistryIntro"),
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: `${window.location.origin}/img/meta_image_grant_search.png`,
          },
        ],
      };
    }
    return {};
  },
  data: function () {
    return {
      isMounted: false,
      rowsPerQuery: 10,
      rowsStart: 0,
      downloadRowsLimit: 1000,
      searchInput: "",
      searchInputValue: "",
      showHelp: false,
      searchHelpTextHtml: "",
      showLoader: false,
      solrGrantQueryQ: null,
      solrGrantQueryData: null,
      solrGrantResponse: null,
      solrFacetQueryData: null,
      solrFacetResponse: null,
      parsedGrants: [],
      routeQuery: [],

      axiosCancelTokenSource: null,

      popoutFilterData: null,
      popoutFilterEntityId: "",
      popoutFilterVisible: false,

      downloadModalVisible: false,
      downloadFormat: "csv",
      downloadPercent: 0,
      // 'init' or 'loading'
      downloadState: "init",
      filterSectionStickyFixed: false,
      filterSectionStickyObserver: null,

      filterEntries: FILTER_ENTRIES_LIST,
    };
  },

  mounted: function () {
    changeQueryParamUnbind = nanoEmitter.on("changeQueryParam", (params) => {
      const { queryParam, value, refetch } = params;
      this.changeQueryParam(queryParam, value, refetch);
    });

    if (this.$route.query.q) {
      this.searchInput = _.trim(this.$route.query.q);
      this.searchInputValue = _.trim(this.$route.query.q);
    }

    this.routeQuery = _.cloneDeep(this.$route.query);
    this.updateCheckedFilterValues();

    this.loadPageData();

    this.filterSectionStickyObserver = new IntersectionObserver(
      ([e]) => {
        // we have to observe the `.header` to get notified when the sticky css for
        // the .filter-section gets fixed to clean up the empty space below with css
        log.trace("filterSectionStickyObserver", e.intersectionRatio);
        this.filterSectionStickyFixed = e.intersectionRatio < 1;
      },
      { threshold: [1] },
    );

    this.filterSectionStickyObserver.observe(document.querySelector(".header"));

    this.isMounted = true;
  },
  beforeDestroy: function () {
    if (this.filterSectionStickyObserver) {
      this.filterSectionStickyObserver.unobserve(document.querySelector(".header"));
      this.filterSectionStickyObserver = null;
    }

    if (changeQueryParamUnbind) {
      changeQueryParamUnbind();
      changeQueryParamUnbind = null;
    }
  },
  computed: {
    ...mapState([
      "grantShowFilterMobile",
      "grantDateMinMax",
      "grantCheckedFilterEntries",
      "grantFilterValueCache",
      "renderActiveFiltersCount",
    ]),
    ...mapGetters([
      "selectedLanguage",
      "cmsData",
      "cmsPageDataArray",
      "screenSize",
      "grantFilterData",
      "grantCheckedFilterEntries",
      "grantCheckedFilterValues",
      "findGrantFilterValue",
      "filterEntriesForEntity",
    ]),
    rowsTotal: function () {
      if (this.solrGrantResponse) {
        return this.solrGrantResponse.response.numFound;
      }
      return 0;
    },
    paginationData: function () {
      return {
        firstPage: 1,
        lastPage: Math.ceil(this.rowsTotal / this.rowsPerQuery),
        currentPage: Math.ceil((this.rowsStart + 1) / this.rowsPerQuery),
      };
    },
    popoutFilterEntityEntry: function () {
      if (this.popoutFilterEntityId) {
        return _.find(GRANT_FILTER_MAPPING, { id: this.popoutFilterEntityId });
      }
      return null;
    },
    popoutGrantCheckedFilterValues: function () {
      return _.flatMap(
        _.filter(
          this.grantCheckedFilterEntries,
          (fe) => fe.filterEntity.id === this.popoutFilterEntityId,
        ),
        "values",
      );
    },
    downloadResultsText: function () {
      return this.cmsTranslationByKey("DownloadResultsText").replace(
        "*{rowsTotal}",
        `<b>${this.rowsTotal}</b>`,
      );
    },
    downloadResultsTextMany: function () {
      return this.cmsTranslationByKey("DownloadResultsTextMany")
        .replace(
          "*{rowsTotal}",
          `<b>${this.numberToStringPandemic(this.rowsTotal)}</b>`,
        )
        .replace(
          "*{rowsLimit}",
          `<b>${this.numberToStringPandemic(this.downloadRowsLimit)}</b>`,
        );
    },
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      //this.loadPageData();
    },
    screenSize: function (val) {
      log.debug("watch screenSize", val);
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
  },
  methods: {
    ...mapMutations([
      "setGrantCheckedFilterEntries",
      "setGrantCheckedLastFilterEntryChange",
      "setGrantShowFilterMobile",
      "setScrollPosition",
      "setGrantDateMinMax",
    ]),
    ...mapActions(["loadGrantFilterData"]),

    debounceSearchInput: _.debounce(function () {
      this.searchInput = _.trim(this.searchInputValue);
      const trimmedSearchInput = _.join(
        splitTrimWords(this.searchInput, 3, true, false),
        " ",
      );
      if (trimmedSearchInput) {
        this.routeQuery["q"] = trimmedSearchInput;
      } else {
        delete this.routeQuery["q"];
      }
      this.updateRouterQuery();
      this.setGrantCheckedLastFilterEntryChange(null);
      this.refetchFullSolrData();
    }, 500),

    loadPageData: function () {
      this.showLoader = true;
      this.loadGrantFilterData().then(() => {
        if (this.selectedLanguage) {
          const pageData = _.find(this.cmsData, { id: "ZWcnLxYAAC4Aj6vN" });
          if (
            pageData &&
            pageData[this.selectedLanguage] &&
            pageData[this.selectedLanguage].data?.text
          ) {
            this.searchHelpTextHtml = PrismicDOM.RichText.asHtml(
              pageData[this.selectedLanguage].data.text,
            );
          }
          this.changeLanguage();
        }
      });
    },

    changeLanguage: function () {
      this.refetchFullSolrData();
    },

    filterShowAll: function (filterEntityId, filterData) {
      log.debug("filterShowAll clicked", filterEntityId);
      //this.setGrantCheckedLastFilterEntryChange(null);
      this.popoutFilterEntityId = filterEntityId;
      this.popoutFilterVisible = true;
      this.popoutFilterData = filterData;
    },

    toggleDateFilter: function (urlQueryParam, selectedMinYear, selectedMaxYear) {
      log.debug("toggleDateFilter", urlQueryParam, selectedMinYear, selectedMaxYear);

      if (
        (!selectedMinYear || selectedMinYear === "*") &&
        (!selectedMaxYear || selectedMaxYear === "*")
      ) {
        delete this.routeQuery[urlQueryParam];
      } else {
        this.routeQuery[urlQueryParam] = `${selectedMinYear || "*"}:${
          selectedMaxYear || "*"
        }`;
      }

      this.updateCheckedFilterValues();
      this.updateRouterQuery();
      this.refetchFullSolrData();
    },

    toggleFilterStatusEntry: function (filterStatusEntry, firstAction = "") {
      let checkedFilterEntriesMap = {};
      const possibleQueryParams = _.flatMap(GRANT_FILTER_MAPPING, "urlQueryParams");

      possibleQueryParams.forEach((param) => {
        checkedFilterEntriesMap[param] = [];
      });
      this.grantCheckedFilterEntries.forEach((fe) => {
        checkedFilterEntriesMap[fe.param] = _.clone(fe.values);
      });

      if (
        filterStatusEntry.children.length &&
        _.some(filterStatusEntry.children, (child) =>
          this.grantCheckedFilterValues.includes(child.value),
        )
      ) {
        log.debug(
          "toggleFilterStatusEntry: filter has children and some are checked -> uncheck all children and check itself",
        );
        filterStatusEntry.children
          .map((ch) => ch.urlQueryParam)
          .forEach((urlQueryParam) => {
            checkedFilterEntriesMap[urlQueryParam] = [];
          });
      }

      if (filterStatusEntry.parentFieldValue) {
        const siblingValues = filterStatusEntry.parent.children
          .filter((ch) => {
            return (
              ch.value !== filterStatusEntry.value &&
              ch.level === filterStatusEntry.level
            );
          })
          .map((ch) => ch.value);

        log.debug("toggleFilterStatusEntry: filter has a parent", siblingValues);

        if (
          filterStatusEntry?.parent?.parent &&
          this.grantCheckedFilterValues.includes(filterStatusEntry.parent.parent.value)
        ) {
          log.debug(
            "toggleFilterStatusEntry: filter grandParent and it is checked -> uncheck grandparent and check all siblings and parent siblings except itself",
          );
          const parentSiblingValues = filterStatusEntry.parent.parent.children
            .filter((ch) => {
              return (
                ch.value !== filterStatusEntry.parent.value &&
                ch.level === filterStatusEntry.parent.level
              );
            })
            .map((ch) => ch.value);
          checkedFilterEntriesMap[filterStatusEntry.parent.parentUrlQueryParam] = [];
          checkedFilterEntriesMap[filterStatusEntry.parentUrlQueryParam] = _.union(
            checkedFilterEntriesMap[filterStatusEntry.parentUrlQueryParam],
            parentSiblingValues,
          );
          checkedFilterEntriesMap[filterStatusEntry.urlQueryParam] = _.union(
            checkedFilterEntriesMap[filterStatusEntry.urlQueryParam],
            siblingValues,
          );
        } else if (
          this.grantCheckedFilterValues.includes(filterStatusEntry.parentFieldValue)
        ) {
          log.debug(
            "toggleFilterStatusEntry: parent is checked -> uncheck parent and check all siblings except itself",
          );
          checkedFilterEntriesMap[filterStatusEntry.parentUrlQueryParam] = _.without(
            checkedFilterEntriesMap[filterStatusEntry.parentUrlQueryParam],
            filterStatusEntry.parentFieldValue,
          );
          checkedFilterEntriesMap[filterStatusEntry.urlQueryParam] = _.union(
            checkedFilterEntriesMap[filterStatusEntry.urlQueryParam],
            siblingValues,
          );
        } else {
          const everySiblingChecked = _.every(siblingValues, (siblingValue) =>
            this.grantCheckedFilterValues.includes(siblingValue),
          );
          if (everySiblingChecked) {
            log.debug(
              "toggleFilterStatusEntry: parent is not checked and all siblings are checked -> check parent and uncheck all siblings",
            );
            checkedFilterEntriesMap[filterStatusEntry.parentUrlQueryParam].push(
              filterStatusEntry.parentFieldValue,
            );
            checkedFilterEntriesMap[filterStatusEntry.urlQueryParam] = [];
          } else {
            log.debug(
              "toggleFilterStatusEntry: parent is not checked and not all siblings are checked -> toggle itself",
            );
            if (
              checkedFilterEntriesMap[filterStatusEntry.urlQueryParam].includes(
                filterStatusEntry.value,
              )
            ) {
              checkedFilterEntriesMap[filterStatusEntry.urlQueryParam] = _.without(
                checkedFilterEntriesMap[filterStatusEntry.urlQueryParam],
                filterStatusEntry.value,
              );
            } else {
              checkedFilterEntriesMap[filterStatusEntry.urlQueryParam].push(
                filterStatusEntry.value,
              );
            }
          }
        }
      } else if (filterStatusEntry.urlQueryParam === "country-type") {
        // there should only be one value checked for `country-type`
        log.debug("toggleFilterStatusEntry: filter is country-type -> toggle itself");
        if (
          checkedFilterEntriesMap[filterStatusEntry.urlQueryParam].includes(
            filterStatusEntry.value,
          )
        ) {
          checkedFilterEntriesMap[filterStatusEntry.urlQueryParam] = _.without(
            checkedFilterEntriesMap[filterStatusEntry.urlQueryParam],
            filterStatusEntry.value,
          );
        } else {
          checkedFilterEntriesMap[filterStatusEntry.urlQueryParam] = [
            filterStatusEntry.value,
          ];
        }
      } else {
        log.debug("toggleFilterStatusEntry: filter has no parent -> toggle itself");
        if (
          checkedFilterEntriesMap[filterStatusEntry.urlQueryParam].includes(
            filterStatusEntry.value,
          )
        ) {
          checkedFilterEntriesMap[filterStatusEntry.urlQueryParam] = _.without(
            checkedFilterEntriesMap[filterStatusEntry.urlQueryParam],
            filterStatusEntry.value,
          );
        } else {
          checkedFilterEntriesMap[filterStatusEntry.urlQueryParam].push(
            filterStatusEntry.value,
          );
        }
      }

      log.debug(
        "toggleFilterStatusEntry: checkedFilterEntriesMap",
        checkedFilterEntriesMap,
      );

      // update routeQuery
      for (const [param, values] of Object.entries(checkedFilterEntriesMap)) {
        if (checkedFilterEntriesMap[param].length) {
          this.routeQuery[param] = checkedFilterEntriesMap[param].join(",");
        } else {
          delete this.routeQuery[param];
        }
      }
      log.debug("toggleFilterStatusEntry: routeQuery", this.routeQuery);

      if (this.routeQuery[filterStatusEntry.urlQueryParam]) {
        this.setGrantCheckedLastFilterEntryChange(filterStatusEntry.filterEntityId);
      } else {
        this.setGrantCheckedLastFilterEntryChange(null);
      }

      if (firstAction === "push") {
        this.updateCheckedFilterValues(filterStatusEntry);
      } else {
        this.updateCheckedFilterValues();
      }
    },

    toggleSearchFilter: function (filterStatusEntry) {
      log.debug("toggleSearchFilter", filterStatusEntry);
      this.toggleFilterStatusEntry(filterStatusEntry);
      this.updateRouterQuery();
      this.refetchFullSolrData();
    },

    changeQueryParam: function (queryParam, value, refetch = false) {
      log.debug("changeQueryParam", queryParam, value, refetch);

      if (value) {
        this.routeQuery[queryParam] = value;
      } else {
        delete this.routeQuery[queryParam];
      }
      this.updateRouterQuery();

      if (refetch) {
        this.refetchFullSolrData();
      }
    },

    updateRouterQuery() {
      this.setScrollPosition({
        x: window.scrollX,
        y: window.scrollY,
      });
      this.$router.push({ query: this.routeQuery }).catch(() => {});
    },

    updateCheckedFilterValues: function (filterStatusEntry) {
      let possibleQueryParams = _.flatMap(GRANT_FILTER_MAPPING, "urlQueryParams");

      const checkedValues = _.map(
        _.pick(this.routeQuery, possibleQueryParams),
        (value, key) => {
          const filterEntity = _.find(GRANT_FILTER_MAPPING, (gfm) => {
            return _.includes(gfm.urlQueryParams, key);
          });
          let values = value.split(",");
          if (key === "call-decision-year") {
            values = values.map((v) => parseInt(v, 10));
          }
          const result = {
            param: key,
            filterEntity: filterEntity,
            values: values,
          };
          if (
            filterStatusEntry &&
            filterEntity.id === filterStatusEntry.filterEntityId
          ) {
            // add the entry field, so that persons not in list can be added later
            result.filterStatusEntry = filterStatusEntry;
          }
          return result;
        },
      );

      log.debug("updateCheckedFilterValues", checkedValues);
      this.setGrantCheckedFilterEntries(checkedValues);
    },

    resetFilters: function () {
      log.debug("resetFilters clicked");

      this.routeQuery = _.pick(this.routeQuery, ["q", "debug"]);
      this.setGrantCheckedFilterEntries([]);
      this.updateRouterQuery();
      this.setGrantCheckedLastFilterEntryChange(null);
      this.refetchFullSolrData();
      this.setGrantShowFilterMobile(false);
    },

    resetEntityFilters: function (filterEntityId) {
      log.debug("resetEntityFilters clicked");

      _.forEach(this.filterEntriesForEntity(filterEntityId), (filterEntry) => {
        this.toggleFilterStatusEntry(filterEntry, "pull");
      });

      this.updateRouterQuery();
      this.refetchFullSolrData();
    },

    resetSearch() {
      log.debug("resetSearch clicked");

      this.routeQuery = _.omit(this.routeQuery, ["q"]);
      this.searchInput = "";
      this.searchInputValue = "";
      this.updateRouterQuery();
      this.refetchFullSolrData();
    },

    toggleHelp(force = false) {
      if (force) {
        this.showHelp = true;
      } else {
        this.showHelp = !this.showHelp;
      }
    },

    updateSolrQueries: function () {
      this.solrGrantQueryQ = {
        Category: "Category:search",
        Entity: "Entity:Grant",
      };

      if (this.searchInput) {
        const textSearchQuery = createTextSearchQuery(this.searchInput);
        if (textSearchQuery) {
          this.solrGrantQueryQ.TextSearch = textSearchQuery;
        }
      }

      let disciplineMulti = false;
      if (
        ["filterDiscipline", "filterFieldsOfResearch"].includes(
          this.$route.query["multi"],
        )
      ) {
        disciplineMulti = true;
        this.solrGrantQueryQ.IsMultiDisciplinary = "IsMultiDisciplinary:true";
      }

      // check other query params
      GRANT_FILTER_MAPPING.flatMap((gfm) => {
        return gfm.urlQueryParams;
      }).forEach((queryParam) => {
        if (queryParam in this.routeQuery) {
          _.forEach(GRANT_FILTER_MAPPING, (gfm) => {
            if (_.includes(gfm.urlQueryParams, queryParam)) {
              if (gfm.type === "output") {
                const solrValues = _.split(this.routeQuery[queryParam], ",");
                _.forEach(solrValues, (solrValue) => {
                  const outputDataType = _.find(OUTPUT_DATA_TYPES, {
                    id: solrValue,
                  });
                  this.solrGrantQueryQ[
                    outputDataType.grantSolrField
                  ] = `${outputDataType.grantSolrField}:*`;
                });
              } else if (gfm.type === "date") {
                const yearValues = _.split(this.routeQuery[queryParam], ":");
                if (yearValues.length === 1) {
                  // duplicate single year
                  yearValues.push(yearValues[0]);
                }
                const index = gfm.urlQueryParams.indexOf(queryParam);
                const solrField = gfm.grantSolrFields[index];
                this.solrGrantQueryQ[
                  solrField
                ] = `${solrField}:[${createSolrFirstDateFromYear(
                  yearValues[0],
                )}%20TO%20${createSolrLastDateFromYear(yearValues[1])}]`;
              } else {
                const index = gfm.urlQueryParams.indexOf(queryParam);
                const solrField = gfm.grantSolrFields[index];
                const solrValues = _.split(this.routeQuery[queryParam], ",");
                const solrParts = _.flatMap(solrValues, (solrValue) => {
                  // CF-1048: handle country-type special
                  if (queryParam === "country-type") {
                    if (gfm.urlQueryParams.includes("country")) {
                      const countryValues = _.map(
                        _.split(this.routeQuery["country"], ","),
                        _.trim,
                      ).filter((v) => v);
                      if (countryValues.length) {
                        // if a country is selected, we add the country iso-code to the `country-type`
                        // like -> `DE-applicants`
                        return _.join(
                          _.map(countryValues, (countryValue) => {
                            return `${solrField}:"${countryValue}-${solrValue}"`;
                          }),
                          "+OR+",
                        );
                      }
                    }
                    // when no country is selected, we have to search for all countries for the `country-type`
                    // with `*`, like -> `*-applicants`
                    return `${solrField}:*-${solrValue}`;
                  }

                  return `${solrField}:"${solrValue}"`;
                });

                let solrJoinSeparator = "+OR+";
                if (
                  ["filterDiscipline", "filterFieldsOfResearch"].includes(gfm.id) &&
                  disciplineMulti
                ) {
                  solrJoinSeparator = "+AND+";
                }

                const solrPart = _.join(solrParts, solrJoinSeparator);
                this.solrGrantQueryQ[solrField] = `(${solrPart})`;
              }
            }
          });
        }
      });

      const multiAndFilterIds = [];
      if (disciplineMulti) {
        multiAndFilterIds.push("filterDiscipline");
        multiAndFilterIds.push("filterFieldsOfResearch");
      }

      this.solrGrantQueryQ = groupGrantQueryLevels(
        this.solrGrantQueryQ,
        multiAndFilterIds,
      );

      log.debug(
        "this.solrGrantQueryQ",
        JSON.parse(JSON.stringify(this.solrGrantQueryQ)),
      );

      this.solrGrantQueryData = {
        start: this.rowsStart.toString(),
        rows: this.rowsPerQuery.toString(),
        sort: `StateSortNumber%20asc,${startDateField}%20desc`,
        q: _.join(_.values(this.solrGrantQueryQ), "+AND+"),
      };
      log.debug(this.solrGrantQueryData);
      log.debug("solrGrantQuery:", createSolrGetRequestUrl(this.solrGrantQueryData));

      // add facet fields
      const facetEntities = GRANT_FILTER_MAPPING.filter((gfm) => {
        return gfm.facetActive;
      });
      const facetLimit = 1000;

      // CF-1048: remove country from facet query to keep correct numbers
      const facetSolrGrantQueryQ = _.cloneDeep(this.solrGrantQueryQ);
      // delete facetSolrGrantQueryQ["filterCountry"];
      // if (facetSolrGrantQueryQ["filterCountryType"]) {
      //   // for country type we have to do the `*`-type search for the facet only
      //   const countryTypeValue = this.routeQuery["country-type"];
      //   if (countryTypeValue) {
      //     facetSolrGrantQueryQ[
      //       "filterCountryType"
      //     ] = `(CountryIsoCodeType_mvf:*-${countryTypeValue})`;
      //   } else {
      //     delete facetSolrGrantQueryQ["filterCountryType"];
      //   }
      // }
      // log.warn("facetSolrGrantQueryQ", facetSolrGrantQueryQ);

      this.solrFacetQueryData = {
        rows: 0,
        facet: true,
        "facet.limit": facetLimit,
        q: _.join(_.values(facetSolrGrantQueryQ), "+AND+"),
        "facet.field": facetEntities.flatMap((gfm) => {
          return gfm.grantSolrFields;
        }),
      };
      _.forEach(facetEntities, (facetEntity) => {
        _.forEach(facetEntity.grantSolrFields, (facetField) => {
          if (facetEntity.facetLimit && facetEntity.facetLimit !== facetLimit) {
            this.solrFacetQueryData[`f.${facetField}.facet.limit`] =
              facetEntity.facetLimit;
          }
        });
      });

      log.debug("solrFacetQuery:", createSolrGetRequestUrl(this.solrFacetQueryData));
    },

    makeSolrDateStatsRequest: function () {
      return axios.get(
        createSolrGetRequestUrl({
          q: "Category:search&Entity:Grant",
          stats: true,
          rows: 0,
          "stats.field": [startDateField, endDateField],
        }),
      );
    },

    refetchFullSolrData: function () {
      this.showLoader = true;
      this.rowsStart = 0;
      this.updateSolrQueries();

      if (this.axiosCancelTokenSource) {
        this.axiosCancelTokenSource.cancel("make new search");
        this.axiosCancelTokenSource = null;
      }

      this.axiosCancelTokenSource = axios.CancelToken.source();
      const requests = [
        makeSolrPostRequest(this.solrGrantQueryData, this.axiosCancelTokenSource.token),
        makeSolrPostRequest(this.solrFacetQueryData, this.axiosCancelTokenSource.token),
      ];

      if (!this.grantDateMinMax) {
        requests.push(this.makeSolrDateStatsRequest());
      }

      axios
        .all(requests)
        .then((responses) => {
          this.solrGrantResponse = responses[0].data;
          this.parsedGrants = this.solrGrantResponse.response.docs;
          this.solrFacetResponse = responses[1].data;
          this.showLoader = false;

          if (responses[2]) {
            const dateData = responses[2].data.stats.stats_fields;
            dateData[startDateField].minYear = parseInt(
              dateData[startDateField].min.substring(0, 4),
            );
            dateData[startDateField].maxYear = parseInt(
              dateData[startDateField].max.substring(0, 4),
            );
            dateData[endDateField].minYear = parseInt(
              dateData[endDateField].min.substring(0, 4),
            );
            dateData[endDateField].maxYear = parseInt(
              dateData[endDateField].max.substring(0, 4),
            );

            this.setGrantDateMinMax(dateData);

            this.$refs.grantRegistrySearchInputHtml.focus();
          }
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            log.debug("Request canceled", thrown.message);
          } else {
            log.error(thrown);
          }
        });
    },

    refetchPaginationSearchQuerySolrData: function () {
      this.showLoader = true;
      this.updateSolrQueries();
      makeSolrPostRequest(this.solrGrantQueryData, this.axiosCancelToken).then(
        (response) => {
          this.solrGrantResponse = response.data;
          this.parsedGrants = this.solrGrantResponse.response.docs;
          this.showLoader = false;
        },
      );
    },

    paginationClickElement: function (itemNumber) {
      if (_.isNumber(itemNumber)) {
        if (itemNumber > 0 && itemNumber <= this.paginationData.lastPage) {
          this.rowsStart = (itemNumber - 1) * 10;
          this.refetchPaginationSearchQuerySolrData();
        }
      }
    },

    paginationCalcElements: function () {
      if (this.paginationData.lastPage <= 8) {
        return _.range(1, this.paginationData.lastPage + 1);
      }

      let result = [];
      _.forEach(_.range(1, 8), (n) => {
        if (n === 1) {
          result.push(n);
          return;
        }
        if (n === 7) {
          result.push(this.paginationData.lastPage);
          return;
        }

        if (this.paginationData.currentPage <= 4 && n <= 5) {
          result.push(n);
          return;
        }

        if (
          this.paginationData.lastPage - this.paginationData.currentPage < 3 &&
          n > 2
        ) {
          result.push(this.paginationData.lastPage - (7 - n));
          return;
        }

        if (n === 2) {
          result.push("...");
          return;
        }
        if (n === 6) {
          result.push("...");
          return;
        }

        result.push(this.paginationData.currentPage + (n - 4));
      });
      return result;
    },

    closePopoutFilter(grantShowFilterMobileFlag) {
      log.debug("closePopoutFilter clicked");
      this.popoutFilterVisible = false;

      this.setGrantShowFilterMobile(grantShowFilterMobileFlag);

      _.delay(() => {
        this.popoutFilterEntityId = null;
      }, 500);
    },

    clickOutsidePopoutFilter() {
      log.debug("clickOutsidePopoutFilter clicked");
      if (this.popoutFilterVisible) {
        this.closePopoutFilter();
      }
    },

    cancelDownload() {
      log.debug("cancelDownload clicked");
      if (this.axiosCancelTokenSource) {
        this.axiosCancelTokenSource.cancel("cancel download");
        this.axiosCancelTokenSource = null;
      }
      this.downloadPercent = 0;
      this.downloadState = "init";
      this.downloadModalVisible = false;
    },

    startDownload() {
      log.debug("startDownload clicked", this.downloadFormat);

      function download(data, filename, type) {
        // from https://stackoverflow.com/a/30832210/669561
        const file = new Blob([data], { type: type });
        if (window.navigator.msSaveOrOpenBlob)
          // IE10+
          window.navigator.msSaveOrOpenBlob(file, filename);
        else {
          // Others
          const a = document.createElement("a");
          const url = URL.createObjectURL(file);
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
        }
      }

      const fields = [
        "AllDisciplines",
        "AmountGrantedAllSets",
        "CallDecisionYear",
        "CallEndDate",
        "CallFullTitle",
        "EffectiveGrantEndDate",
        "EffectiveGrantStartDate",
        "FundingInstrumentLevel1",
        "FundingInstrumentPublished",
        "FundingInstrumentReporting",
        "GrantNumber",
        "GrantNumberString",
        "Institute",
        "InstituteCountry",
        "Keywords",
        "MainDiscipline",
        "MainDiscipline_Level1",
        "MainDiscipline_Level2",
        "MainDisciplineNumber",
        "MainFieldOfResearch_LevelA",
        "MainFieldOfResearch_LevelB",
        "MainFieldOfResearchNumber",
        "ResearchInstitution",
        "ResponsibleApplicantName",
        "State",
        "Title",
        "TitleEnglish",
      ];

      let solrQueryData = _.clone(this.solrGrantQueryData);
      solrQueryData.rows = this.downloadRowsLimit;
      solrQueryData.start = 0;
      solrQueryData.fl = _.join(fields, ",");

      this.axiosCancelTokenSource = axios.CancelToken.source();
      this.downloadPercent = 0;
      this.downloadState = "loading";

      makeSolrPostRequest(
        solrQueryData,
        this.axiosCancelTokenSource.token,
        (progress) => {
          this.downloadPercent = _.clamp(progress.loaded / 2000000, 0, 1) * 100;
          log.debug(this.downloadPercent);
        },
      ).then((response) => {
        this.downloadPercent = 100;
        const grants = response.data.response.docs;

        const data = grants.map((g) => {
          let result = [];
          fields.forEach((f) => {
            // if (g[f] && (f === startDateField || f === endDateField) && this.downloadFormat === 'xls') {
            //   // convert date to excel date format
            //   const d = new Date(g[f].substring(0, 10));
            //   d.setHours(0, 0, 0, 0);
            //   result.push(d);
            // } else {
            result.push(g[f]);
            // }
          });
          return result;
        });
        data.unshift(fields);
        const ws_name = "Grants";
        const wb = XLSX.utils.book_new(),
          ws = XLSX.utils.aoa_to_sheet(data);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        /* write workbook */
        if (this.downloadFormat === "csv") {
          download(
            XLSX.utils.sheet_to_csv(ws, { FS: ";" }),
            "GrantSearchResults.csv",
            "text/csv;encoding:utf-8",
          );
          this.cancelDownload();
        } else {
          XLSX.writeFile(wb, "GrantSearchResults.xlsx");
          this.cancelDownload();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

$left-side-width: 304px;
$right-side-width: 246px;

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.search-input {
  border-radius: 0;
  height: 44px;

  &::placeholder {
    color: $snf-gray-dark;
    opacity: 1;
  }
}

.grant-registry-index {
  border-top: $snf-gray-light 1px solid;
}

.grant-registry-section {
  padding: 0 24px;
}

.title-section {
  border-bottom: $snf-gray-light 1px solid;
  padding: 24px;

  // box-shadow: 0 10px 10px -10px $snf-gray-light;

  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  margin-bottom: 0;
}

.toggle-filter-mobile-section {
  padding: 24px 24px 0 24px;
  background: white;
  position: sticky;
  top: 69px;
  display: none;
  border-bottom: 1px solid $snf-gray-light;
  box-shadow: 0 1px 10px $snf-gray-light;
}

.filter-section__close {
  display: none;
}

.filter-section__title {
  h4 {
    font-size: 18px;
  }
}

.grant-registry-title {
  font-size: 28px;
}

.grant-registry-search {
  width: 100%;
}

.grant-registry-search-help {
  padding: 16px;
  border: #cbe0eb 1px solid;
  line-height: 1.5;
  position: relative;

  ul {
    margin: 0 8px;
    padding: 0 8px;
    list-style: disc;
  }

  li {
    margin: 16px 0;
  }

  .search-help-close {
    position: absolute;
    right: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  .search-help-title {
    color: $snf-blue-font;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .search-help-highlight {
    background: #cbe0eb;
    padding: 2px 6px;
  }
}

.grant-registry-search-form {
  position: relative;
  display: flex;

  .grant-registry-search-input {
    width: 100%;

    input {
      padding: 15px 10px 10px 10px;
      line-height: 1;
    }
  }

  .grant-registry-search-button {
    position: absolute;
    top: 3px;
    right: 40px;

    padding: 12px 1em;
    text-transform: none;
    border: none;
    cursor: pointer;
    color: $snf-blue-font;
    background: none;

    &:hover {
      color: black;
    }
  }
}

.grant-registry-help-div {
  width: 48px;

  .grant-registry-help-button {
    padding: 6px;
    background: none;
    border: none;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

.grants-number {
  margin: 16px 0 8px 0;
  font-size: 14px;
}

.grant-entry {
  border-top: $snf-gray-light 1px solid;
}

.search-results-loader {
  width: 100%;
}

.section-grant-actions {
  padding-top: 16px;
  border-top: $snf-gray-light 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  color: $snf-blue-font;
  display: flex;
  margin: 16px 0;

  .pagination-element {
    padding: 4px 0;
    margin: 0 2px;
    cursor: pointer;
    min-width: 32px;
    text-align: center;

    img {
      height: 16px;
      @include snf-blue-font-filter;
      transform: translate(0, 3px);
    }
  }

  .pagination-selected {
    color: white;
    background: $snf-blue-font;
    border-radius: 50%;
  }
}

.active-filters {
}

.popout-filter {
  height: 100%;
  width: 0;
  max-width: 100vw;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  border-right: $snf-gray-medium 1px solid;
  border-top: $snf-gray-medium 1px solid;
  visibility: visible;

  @include touch() {
    margin-top: 0;
    transition: 0s;
  }

  &--visible {
    visibility: visible;
    width: 500px;

    @include touch() {
      width: 100%;
    }
  }

  .popout-filter__header {
    $badge-size: 32px;
    line-height: $badge-size;

    height: 66px;

    font-size: 18px;
    border-bottom: 1px solid $snf-gray-light;
    box-shadow: 0 1px 10px $snf-gray-light;

    padding: 16px 32px 16px 40px;

    display: flex;
    justify-content: space-between;

    .popout-filter__header__text {
      flex: 1 1 auto;
      display: flex;
      line-height: $badge-size;
      margin-left: 16px;

      .badge {
        margin-left: 16px;
        @include badge($badge-size);
      }
    }
  }

  .popout-filter__buttons {
    border-top: 1px solid $snf-gray-light;
    $popout-filter__buttons-height: 84px;
    height: 84px;

    display: flex;
    justify-content: flex-end;

    padding: 8px 32px 8px 40px;
    box-shadow: 0 -1px 10px $snf-gray-light;

    button {
      flex: 1 1 100%;
      margin: 8px;
      font-weight: 700;
    }
  }

  .popout-filter__content {
    overflow-y: scroll;
    height: calc(100% - 84px - 66px);

    // CF-999: show scrollbars
    //// hide scrollbar https://stackoverflow.com/a/38994837/669561
    //-ms-overflow-style: none;  // IE 10+
    //scrollbar-width: none;  // Firefox
    //&::-webkit-scrollbar {
    //  display: none;  // Chrome etc
    //}
  }
}

.results-download-button {
  color: $snf-blue-font;
  padding: 0;
  text-transform: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    @include snf-blue-font-filter;
  }
}

.download-modal {
  display: none;

  &--visible {
    display: block;
  }

  .modal-backdrop {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-body {
    z-index: 1001;
    position: fixed;
    background: white;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 560px;
    margin: auto;
    overflow: auto;
    font-size: 18px;
    display: flex;
    flex-direction: column;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 16px;
      border-bottom: 1px solid $snf-gray-light;
      font-weight: 700;
      line-height: 20px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .modal-content {
      padding: 24px 16px;
      margin: 0;
      flex: 1 1 auto;
      max-width: 560px;

      .radio-buttons {
        display: flex;

        .radio-button {
          margin-left: 32px;

          label {
            margin-left: 8px;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }

      .download-percent-bar {
        margin: 32px 0;
        width: 100%;
        height: 16px;
        position: relative;
        border: 1px solid $snf-blue-dark;

        &--full {
          position: absolute;
          top: 0;
          left: 0;
          height: 16px;
          background: $snf-blue;
        }
      }
    }

    .modal-buttons {
      padding: 24px 16px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      border-top: 1px solid $snf-gray-light;
      font-size: 16px;

      button {
        width: 100%;
      }

      .modal-cancel {
        margin-top: 16px;
      }

      .modal-ok {
        font-weight: 700;
      }
    }
  }
}

@include desktop() {
  .grant-registry-header {
    display: flex;

    .grant-registry-title {
      flex: 0 0 ($left-side-width + 16px);
    }

    .grant-registry-search-form {
      flex-direction: row;
      flex: 1 1 auto;
      justify-content: space-between;
    }
  }

  .pagination {
    .pagination-element {
      padding: 8px 0;
      min-width: 40px;
      margin: 0 4px;
    }
  }

  .grant-registry-section {
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
  }

  .grant-registry-title {
    font-size: 36px;
    margin-bottom: 0;
  }

  .title-section {
    padding: 16px 40px;
  }

  .results-section {
    padding: 16px 40px;
    display: flex;

    .filter-section {
      flex: 0 0 $left-side-width;
      padding-right: 32px;
      margin-right: 16px;

      position: sticky;
      top: 66px;
      height: 99%;

      max-height: calc(100vh - 180px);
      overflow-y: auto;

      &--sticky-fixed {
        max-height: calc(100vh - 70px);
      }
    }

    .search-results-section {
      flex: 1 1 auto;
    }
  }

  .grants-number {
    margin-top: 0;
  }

  .download-modal {
    .modal-body {
      height: 360px;

      .modal-buttons {
        flex-direction: row;

        .modal-cancel {
          margin-right: 8px;
          margin-top: 0;
        }

        .modal-ok {
          margin-left: 8px;
          margin-top: 0;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1380px) {
  .results-section {
    .filter-section {
      max-height: calc(100vh - 240px);

      &--sticky-fixed {
        max-height: calc(100vh - 70px);
      }
    }
  }
}

@media (min-width: 1380px) {
  .right-space {
    flex: 0 0 $right-side-width;
  }
}

@include touch() {
  .section-grant-actions {
    flex-direction: column;

    .results-download-section {
      margin: 16px;
    }
  }

  .title-section {
    position: sticky;
    top: -60px;
    padding-bottom: 16px;
  }

  .filter-section {
    display: none;
  }

  .mobile-fixed {
    position: fixed;
    overflow: hidden;
  }

  .toggle-filter-mobile-section {
    display: block;
  }

  .toggle-filter-mobile {
    button {
      $badge-size: 32px;
      line-height: $badge-size;
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: stretch;

      img {
        margin-right: 16px;
      }

      .badge {
        @include badge($badge-size);
      }
    }
  }

  .filter-section--mobile-visible {
    .filter-section__title {
      margin-top: 32px;
      margin-left: -40px;
      margin-right: -32px;
      border-bottom: 1px solid $snf-gray-light;
      padding-bottom: 16px;
      display: flex;
      justify-content: space-between;
      box-shadow: 0 10px 10px -10px $snf-gray-light;

      h4 {
        margin-left: 40px;
      }

      .filter-section__close {
        display: block;
        margin-right: 32px;
      }
    }

    display: block;
    height: 100%;
    max-width: 100vw;
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    border-right: $snf-gray-medium 1px solid;
    border-top: $snf-gray-medium 1px solid;

    width: 100%;
    padding-left: 40px;
    padding-right: 32px;

    .filter-entry {
      padding: 4px 0 12px;
      border-bottom: 1px solid $snf-gray-light;
    }
  }
}
</style>
