<template>
  <div class="documentation-raw-container">
    <div
      class="documentation-raw"
      :style="{ 'max-width': maxWidth ? maxWidth : '768px' }"
    >
      <div v-html="textHtml" class="prismic-raw" />

      <div class="boxes" v-if="routePath === '/datasets'">
        <div
          class="box-row"
          :key="index"
          v-for="(datasetObjects, index) in datasetFields"
        >
          <div class="box" v-if="datasetObjects[0]">
            <div>
              <h3>{{ objectAttributeInLanguage(datasetObjects[0], "title") }}</h3>
              <p>
                {{ objectAttributeInLanguage(datasetObjects[0], "description") }}
              </p>
            </div>
            <div class="box-download">
              <a
                :href="datasetObjects[0].csvUrl"
                class="download-button"
                v-if="datasetObjects[0].csvUrl"
              >
                <span class="hide-mobile">Download</span>
                CSV
              </a>
              <a
                :href="datasetObjects[0].xlsxUrl"
                class="download-button"
                v-if="datasetObjects[0].xlsxUrl"
              >
                <span class="hide-mobile">Download</span>
                XLSX
              </a>
            </div>
          </div>
          <div class="box" v-if="datasetObjects[1]">
            <div>
              <h3>{{ objectAttributeInLanguage(datasetObjects[1], "title") }}</h3>
              <p>
                {{ objectAttributeInLanguage(datasetObjects[1], "description") }}
              </p>
            </div>
            <div class="box-download">
              <a
                :href="datasetObjects[1].csvUrl"
                class="download-button"
                v-if="datasetObjects[1].csvUrl"
              >
                <span class="hide-mobile">Download</span>
                CSV
              </a>
              <a
                :href="datasetObjects[1].xlsxUrl"
                class="download-button"
                v-if="datasetObjects[1].xlsxUrl"
              >
                <span class="hide-mobile">Download</span>
                XLSX
              </a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="textHtml2" v-html="textHtml2" class="prismic-raw" />

      <div v-if="routePath === '/about/dataportal'" class="prismic-raw" style="font-size: 1.1rem">
        <div>
          {{ cmsTranslationByKey("DataPortalTitle") }} Version:
          2025-03-10 13:48:14 fb3ecc8
        </div>
      </div>
    </div>

    <nav
      v-if="routePath === '/key-figures/documentation'"
      class="cf-nav snf-section snf-section__gray"
    >
      <div class="cf-nav__section snf-section__columns columns is-mobile">
        <div class="cf-nav__left cf-nav__item column">
          <router-link class="text-decoration-none" to="/key-figures">
            <span>{{ cmsTranslationByKey("BackCoreFiguresHome") }}</span>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import * as _ from "lodash";
import PrismicDOM from "prismic-dom";
import * as log from "loglevel";
import { cmsTranslationMixin } from "@/mixins";
import axios from "axios";

export default {
  name: "DocumentationRaw",
  props: ["cmsId", "maxWidth"],
  mixins: [cmsTranslationMixin],
  metaInfo: function () {
    if (this.selectedLanguage && this.cmsData) {
      if (this.$route.name === "Datasets") {
        return {
          title: this.cmsTranslationByKey("DatasetsTitle"),
          meta: [
            {
              vmid: "og:title",
              property: "og:title",
              content: `${this.cmsTranslationByKey(
                "DataPortalTitle",
              )}: ${this.cmsTranslationByKey("DatasetsTitle")}`,
            },
            {
              vmid: "og:description",
              property: "og:description",
              content: this.cmsTranslationByKey("DatasetsIntroShort"),
            },
            {
              vmid: "og:image",
              property: "og:image",
              content: `${window.location.origin}/img/meta_image_datasets.png`,
            },
          ],
        };
      }
    }
    return {};
  },
  data: function () {
    return {
      textHtml: "",
      textHtml2: "",
      datasetFields: [],
      datasetObjects: [],
    };
  },
  mounted: function () {
    log.debug(this.cmsId, this.maxWidth);
    this.loadPageData();
  },
  computed: {
    ...mapGetters(["selectedLanguage", "cmsData", "screenSize", "cmsPageDataArray"]),
    routePath: function () {
      return this.$route.path;
    },
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  methods: {
    loadPageData() {
      if (this.selectedLanguage && this.cmsData) {
        log.debug("DocumentationRaw loadPageData!");

        // test url https://odp-test.snf.ch/
        let url =
          "https://stodppublicstoragetest.blob.core.windows.net/datasets/catalog.json";
        if (window.location.origin.startsWith("https://data.snf.ch")) {
          url =
            "https://stodppublicstorageprod.blob.core.windows.net/datasets/catalog.json";
        } else if (
          window.location.origin.startsWith("https://odp-int.snf.ch") ||
          window.location.origin.startsWith("https://odp.snf.ch/")
        ) {
          url =
            "https://stodppublicstorageint.blob.core.windows.net/datasets/catalog.json";
        }

        if (this.routePath === "/datasets") {
          axios({
            url: url,
          }).then((response) => {
            this.datasetObjects = response.data;
            this.datasetFields = _.chunk(this.datasetObjects, 2);
          });
        }

        if (_.isArray(this.cmsId)) {
          const pageDatas = _.filter(this.cmsData, (pageData) => {
            return _.includes(this.cmsId, pageData.id);
          });
          this.textHtml = "";
          _.forEach(pageDatas, (pageData) => {
            this.textHtml += PrismicDOM.RichText.asText(
              pageData[this.selectedLanguage].data.documentation_page_raw_pre,
            );
          });
        } else {
          const pageData = _.find(this.cmsData, { id: this.cmsId });
          this.textHtml = PrismicDOM.RichText.asText(
            pageData[this.selectedLanguage].data.documentation_page_raw_pre,
          );
          if (this.routePath === "/datasets") {
            const results = _.split(this.textHtml, /<div id="dataset_tiles"> <\/div>/);
            this.textHtml = results[0];
            this.textHtml2 = results[1];
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/colors";
@import "../assets/css/bulma_utils";

.documentation-raw-container {
  border-top: 1px solid $snf-gray-light;
}

.documentation-raw {
  padding-top: 32px;

  margin-left: auto;
  margin-right: auto;
}

.prismic-raw {
  padding: 1em 10px;

  font-weight: normal;
  line-height: 1.28;

  h1 {
    font-size: 36px;
    font-weight: 700;
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
  }

  h3 {
    font-size: 20px;
    margin-top: 1.2em;
  }

  a {
    color: $snf-black;
    text-decoration: underline;
  }

  p {
    line-height: 1.75;
    font-size: 1.1rem;
    margin: 1em 0 1.5em 0;
  }

  ul,
  ol {
    line-height: 1.5;
    font-size: 1.1rem;
    margin: 1em 0 1.5em 0;
  }

  li {
    font-weight: normal;
    list-style-type: disc;
    margin-left: 1.5em;
  }
}

.boxes {
  margin-bottom: 32px;
}

.box-row {
  .box {
    border: 1px solid $snf-gray-medium;
    padding: 16px;
    margin: 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

@include desktop() {
  .prismic-raw {
    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 28px;
    }
  }

  .box-row {
    display: flex;

    .box {
      flex: 1 1 50%;
      margin: 8px;
    }
  }
}
</style>
