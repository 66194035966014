var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.cmsPageDataObject)?_c('section',{staticClass:"snf-section background__blue-light"},[_c('div',{staticClass:"snf-section__columns columns home__columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"title-column"},[_c('h1',{staticClass:"key-figures-title",domProps:{"innerHTML":_vm._s(_vm.titleText)}}),_c('p',{staticClass:"lead",domProps:{"innerHTML":_vm._s(_vm.cfKpiSentence)}})]),_c('div',{staticClass:"key-figures-tiles"},_vm._l((_vm.cmsPageDataArray.filter(
              (p) => p.pageType === 'CoreFigures',
            )),function(subPage){return _c('div',{key:subPage.pageTitle,staticClass:"key-figure-tile"},[_c('div',{staticClass:"key-figure-tile__img"},[_c('img',{attrs:{"src":subPage.icon,"alt":"icon"}})]),_c('div',{staticClass:"key-figure-tile__text"},[_c('h4',{staticClass:"key-figure-tile__title"},[_vm._v(" "+_vm._s(subPage.pageTitle)+" ")]),_c('router-link',{key:subPage.title,staticClass:"explore-link",attrs:{"to":subPage.url}},[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("CoreFiguresExploreData"))+" ")])],1)])}),0)])])]):_vm._e(),(_vm.cmsPageDataObject)?_c('section',{staticClass:"snf-section"},[_c('div',{staticClass:"snf-section__columns columns home__columns"},[_c('div',{staticClass:"column bar-chart-column"},[_c('div',{staticClass:"text"},[_c('h3',[_vm._v(_vm._s(_vm.cfTitleSentence))]),_c('div',{staticClass:"lead",domProps:{"innerHTML":_vm._s(_vm.cfResultSentence)}}),_c('div',{staticStyle:{"margin-top":"0.5em"}},[_c('a',{staticClass:"explore-link",attrs:{"href":"/key-figures/funding-instruments"}},[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("CoreFiguresExploreData"))+" ")])])]),_c('div',{staticClass:"main-chart"},[_c('div',{staticClass:"bar-chart"},_vm._l((_vm.cfPictureData),function(item){return _c('div',{key:item.label,staticClass:"bar",style:({
                height: (item.Funding / _vm.cfPictureData[0].Funding) * 200 + 'px',
              })},[_c('div',{staticClass:"bar-number"},[_vm._v(" "+_vm._s(_vm.numberToStringFormat(item.Funding))+" ")]),_c('div',{staticClass:"bar-label",style:({
                  top: (item.Funding / _vm.cfPictureData[0].Funding) * 200 + 4 + 'px',
                })},[_vm._v(" "+_vm._s(item.label === "Wissenschaftskommunikation" ? "Wissenschafts- kommunikation" : item.label)+" ")])])}),0),_c('div',{staticClass:"bar-legend"},_vm._l((_vm.cfPictureData),function(item){return _c('div',{key:item.label,staticClass:"bar-legend-item"},[_c('div',{staticClass:"dot"}),_c('div',[_vm._v(_vm._s(item.label))])])}),0)])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }