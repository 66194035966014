const pageDataArray = [
  {
    entityUrl: "fundingInstruments",
    pageType: "CoreFigures",
    colorClass: "color-1",
    icon: "/img/cf-waffle-chart.svg",
    sentenceEntity: "FundingInstruments",
    solrEntity: "FundingInstruments",
    solrLevelAttribute: true,
    cmsId: "XbcSwBYAACQA9OL4",
    url: "/key-figures/funding-instruments",
    csvUrl: "/public_storage/datasets/FundingInstruments.csv",
    excelUrl: "/public_storage/datasets/FundingInstruments.xlsx",
  },
  {
    entityUrl: "researchInstitutions",
    pageType: "CoreFigures",
    colorClass: "color-1",
    icon: "/img/cf-waffle-chart.svg",
    sentenceEntity: "ResearchInstitutions",
    solrEntity: "ResearchInstitutionsFundingInstrumentsL1",
    solrLevelAttribute: true,
    cmsId: "XbcTNxYAACUA9OT1",
    url: "/key-figures/research-institutions",
    csvUrl: "/public_storage/datasets/ResearchInstitutionsFundingInstruments.csv",
    excelUrl: "/public_storage/datasets/ResearchInstitutionsFundingInstruments.xlsx",
  },
  {
    entityUrl: "disciplines",
    pageType: "CoreFigures",
    colorClass: "color-1",
    icon: "/img/cf-waffle-chart.svg",
    sentenceEntity: "Disciplines",
    solrEntity: "Disciplines",
    solrLevelAttribute: true,
    cmsId: "Xbf_YBYAACYA-Oxl",
    url: "/key-figures/disciplines",
    csvUrl: "/public_storage/datasets/DisciplinesPerFundingInstrument.csv",
    excelUrl: "/public_storage/datasets/DisciplinesPerFundingInstrument.xlsx",
  },
  {
    entityUrl: "demographics",
    pageType: "CoreFigures",
    colorClass: "color-1",
    icon: "/img/cf-bar-chart.svg",
    sentenceEntity: "Demographics",
    solrEntity: "Demographics",
    solrLevelAttribute: false,
    solrAttributeYear: "Year",
    cmsId: "Xbf_5xYAACUA-O5f",
    url: "/key-figures/demographics",
    csvUrl: "/public_storage/datasets/Demographics.csv",
    excelUrl: "/public_storage/datasets/Demographics.xlsx",
  },
  {
    entityUrl: "internationality",
    pageType: "CoreFigures",
    colorClass: "color-1",
    icon: "/img/cf-globe.svg",
    sentenceEntity: "Internationality",
    solrEntity: "Internationality",
    solrLevelAttribute: true,
    solrAttributeYear: "Year",
    cmsId: "XbgCJBYAACUA-PhO",
    url: "/key-figures/internationality",
    csvUrl: "/public_storage/datasets/Internationality.csv",
    excelUrl: "/public_storage/datasets/Internationality.xlsx",
  },
  {
    entityUrl: "supplementalFunding",
    pageType: "CoreFigures",
    colorClass: "color-1",
    icon: "/img/cf-waffle-chart.svg",
    sentenceEntity: "SupplementaryFunding",
    solrEntity: "SupplementaryFunding",
    solrLevelAttribute: "SupplementaryFundingType",
    solrAttributeYear: "DecisionYear",
    cmsId: "XbgCVRYAACcA-Pkp",
    url: "/key-figures/supplemental-funding",
    csvUrl: "/public_storage/datasets/SupplementaryFundingPerResearchInstitution.csv",
    excelUrl: "/public_storage/datasets/SupplementaryFundingPerResearchInstitution.xlsx",
  },
  {
    entityUrl: "nccr",
    pageType: "CoreFigures",
    colorClass: "color-1",
    icon: "/img/cf-table.svg",
    cmsId: "Xc7UQBEAACIAH3zU",
    url: "/key-figures/nccr",
  },
  {
    entityUrl: "cfDocumentation",
    pageType: "CoreFigures",
    colorClass: "color-1",
    icon: "/img/cf-document.svg",
    cmsId: "XdeX8BEAACQARl6F",
    url: "/key-figures/documentation",
  },
  {
    entityUrl: "pandemic",
    pageType: "Covid19",
    colorClass: "color-1",
    sentenceEntity: "Pandemic",
    solrEntity: "Pandemic",
    cmsId: "Xos8GRAAALlTtsbY",
    url: "/covid-19",
  },
  {
    entityUrl: "covidDocumentation",
    pageType: "Documentation",
    colorClass: "color-1",
    cmsId: "XoZPPhAAAHB3oNxt",
    url: "/covid-19/documentation",
  },
  {
    entityUrl: "covidDataset",
    pageType: "Documentation",
    colorClass: "color-1",
    cmsId: "XpXfWRIAACIAp2gg",
    url: "/covid-19/documentation",
  },
];

export default pageDataArray;
