import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { GRANT_FILTER_MAPPING, parseSolrCmsResponse } from "./utils";
import * as _ from "lodash";
import * as log from "loglevel";
import pageDataArray from "./pageDataArray";
import { nanoEmitter } from "@/events";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    screenSize: "mobile",
    selectedLanguage: "en",
    cmsData: null,
    cmsTranslationData: null,
    cmsPageDataArray: [],
    cmsPageDataObject: null,
    mobileMenuShown: false,
    dataStoriesTagFilterShown: false,
    dataStoriesTags: [],
    grantFilterData: null,
    grantCheckedFilterEntries: [],
    grantCheckedLastFilterEntryChange: null,
    grantPersonNames: [],
    grantDateMinMax: null,
    grantShowFilterMobile: false,
    scrollPosition: null,
    enableGrantSearch: true,
    grantFilterValueCache: {},
    renderActiveFiltersCount: 0,
    grantSearchStartMinYear: 1975,
    grantSearchStartMaxYear: 2022,
    grantSearchEndMinYear: 1975,
    grantSearchEndMaxYear: 2022,
  },
  getters: {
    screenSize: (state) => state.screenSize,
    selectedLanguage: (state) => state.selectedLanguage,
    cmsData: (state) => state.cmsData,
    cmsTranslationData: (state) => state.cmsTranslationData,
    cmsPageDataArray: (state) => state.cmsPageDataArray,
    cmsPageDataObject: (state) => state.cmsPageDataObject,
    mobileMenuShown: (state) => state.mobileMenuShown,
    dataStoriesTagFilterShown: (state) => state.dataStoriesTagFilterShown,
    dataStoriesTags: (state) => state.dataStoriesTags,
    grantFilterData: (state) => state.grantFilterData,
    grantCheckedFilterEntries: (state) => state.grantCheckedFilterEntries,
    grantCheckedFilterValues: (state) =>
      _.flatMap(state.grantCheckedFilterEntries, "values"),
    grantCheckedLastFilterEntryChange: (state) =>
      state.grantCheckedLastFilterEntryChange,
    grantPersonNames: (state) => state.grantPersonNames,
    isMobile: (state) => state.screenSize === "mobile",
    isTablet: (state) => state.screenSize === "tablet",
    isMobileOrTablet: (state) =>
      state.screenSize === "mobile" || state.screenSize === "tablet",
    enableGrantSearch: (state) => state.enableGrantSearch,
    findGrantFilterValue: (state) => (filterValueId) => {
      if (filterValueId && state.grantFilterValueCache) {
        return state.grantFilterValueCache[filterValueId];
      }
    },
    filterEntriesForEntity: (state, getters) => (filterEntityId) => {
      return _.compact(
        getters.grantCheckedFilterValues.map((v) => {
          return getters.findGrantFilterValue(v);
        }),
      ).filter((v) => {
        if (filterEntityId === "filterCountry") {
          return (
            v.filterEntityId === "filterCountry" ||
            v.filterEntityId === "filterCountryType"
          );
        }
        return v.filterEntityId === filterEntityId;
      });
    },
  },
  mutations: {
    setScreenSize(state, screenSize) {
      state.screenSize = screenSize;
    },
    setSelectedLanguage(state, selectedLanguage) {
      if (_.includes(["de", "fr", "en"], selectedLanguage)) {
        window.localStorage.setItem("selectedLanguage", selectedLanguage);
        state.selectedLanguage = selectedLanguage;
      }
    },
    setMobileMenuShown(state, mobileMenuShown) {
      state.mobileMenuShown = mobileMenuShown;
    },
    setDataStoriesTagFilterShown(state, flagState) {
      state.dataStoriesTagFilterShown = flagState;
    },
    setGrantCheckedFilterEntries(state, data) {
      state.grantCheckedFilterEntries = data;
    },
    setGrantCheckedLastFilterEntryChange(state, value) {
      state.grantCheckedLastFilterEntryChange = value;
    },
    setGrantPersonNames(state, data) {
      state.grantPersonNames = data;
    },
    setGrantFilterData(state, data) {
      state.grantFilterData = data;
    },
    setGrantShowFilterMobile(state, flag) {
      state.grantShowFilterMobile = flag;
    },
    setGrantDateMinMax(state, data) {
      state.grantDateMinMax = data;
    },
    setDataStoriesTags(state, data) {
      state.dataStoriesTags = data;
    },
    setScrollPosition(state, position) {
      state.scrollPosition = position;
    },
    setCmsPageData(state) {
      if (state.cmsData) {
        const cmsPageDataArray = _.clone(pageDataArray);
        _.forEach(cmsPageDataArray, (p) => {
          const cmsPageData = _.find(state.cmsData, {
            id: p.cmsId,
          });

          if (
            cmsPageData &&
            cmsPageData[state.selectedLanguage].data &&
            !cmsPageData[state.selectedLanguage].data.documentation_page_raw_pre
          ) {
            p.pageTitle = cmsPageData[state.selectedLanguage].data.title[0].text;
            if (cmsPageData[state.selectedLanguage].data.headline_statement) {
              p.pageHeaderText =
                cmsPageData[state.selectedLanguage].data.headline_statement[0].text;
            } else {
              p.pageHeaderText = "";
            }
          }
        });
        state.cmsPageDataArray = cmsPageDataArray;
        state.cmsPageDataObject = _.keyBy(cmsPageDataArray, "entityUrl");
      }
    },
    updateGrantFilterValueCache(state, filterData) {
      filterData.forEach((filterDataEntry) => {
        state.grantFilterValueCache[filterDataEntry.value] = filterDataEntry;
      });
      state.renderActiveFiltersCount += 1;
    },
    setMinMaxYears(state, payload) {
      if (payload.queryParam === "end") {
        state.grantSearchEndMinYear = payload.minYear;
        state.grantSearchEndMaxYear = payload.maxYear;
      } else {
        state.grantSearchStartMinYear = payload.minYear;
        state.grantSearchStartMaxYear = payload.maxYear;
      }
    },
  },
  actions: {
    setScreenSize({ commit }, screenSize) {
      commit("setScreenSize", screenSize);
    },
    setSelectedLanguage({ commit }, selectedLanguage) {
      commit("setSelectedLanguage", selectedLanguage);
      commit("setCmsPageData");
    },
    loadDataStoriesTags({ commit, state }) {
      if (state.dataStoriesTags.length > 0) {
        return Promise.resolve();
      }
      return axios
        .all([
          axios.get(
            "/solr/search/select/?rows=1000&q=Category%3Aexcel+AND+Entity:DataStories_Tags",
          ),
          axios.get(
            "/solr/search/select/?rows=1000&q=Category:stats+AND+Entity:DataStories&fl=Tags&fq=PublicationDate:[2020-01-01T00:00:00Z%20TO%20NOW]",
          ),
        ])
        .then((responses) => {
          const storyTags = responses[1].data.response.docs;
          const usedTags = _.uniq(
            _.reduce(
              storyTags,
              (result, solrDoc) => {
                return _.concat(result, solrDoc.Tags.split(","));
              },
              [],
            ),
          );
          commit(
            "setDataStoriesTags",
            _.map(responses[0].data.response.docs, (solrTagObject) => {
              solrTagObject.usedInStory = _.includes(usedTags, solrTagObject.TagId);
              return solrTagObject;
            }),
          );
        });
    },
    loadGrantFilterData({ commit, state }) {
      if (state.grantFilterData) {
        return Promise.resolve();
      }
      const filterEntityPart = _.join(
        _.map(
          _.filter(GRANT_FILTER_MAPPING, (m) => m.entity),
          (m) => {
            return `Entity:${m.entity}`;
          },
        ),
        "+OR+",
      );

      let url = `/solr/search/select/?rows=5000&q=Category:search+AND+(${filterEntityPart})`;

      return axios.get(url).then((response) => {
        commit("setGrantFilterData", response.data);
      });
    },
    loadPersonNames({ commit, state }, personIds, cancelTokenSource) {
      const loadPersonIds = _.difference(
        personIds,
        _.map(state.grantPersonNames, "PersonId"),
      );

      if (loadPersonIds.length > 0) {
        const solrPersonIdPart = _.join(
          _.map(loadPersonIds, (pid) => {
            return `PersonId:${pid}`;
          }),
          "+OR+",
        );
        const solrQuery = `/solr/search/select/?rows=${loadPersonIds.length}&fl=PersonNumber,PersonId,Surname,FirstName&q=Category:search+AND+Entity:Person+AND+(${solrPersonIdPart})`;

        let config = {};
        if (cancelTokenSource) {
          config.cancelToken = cancelTokenSource.token;
        }

        return axios.get(solrQuery, config).then((response) => {
          const grantPersonNames = _.uniqBy(
            _.concat(state.grantPersonNames, response.data.response.docs),
            "PersonId",
          );
          commit("setGrantPersonNames", grantPersonNames);
          return grantPersonNames;
        });
      } else {
        return Promise.resolve();
      }
    },
    loadCmsData({ commit, state }) {
      return axios({
        url: "/solr/search/select/?rows=1000&q=Category%3Acms",
      }).then((response) => {
        const parsedCmsResponse = parseSolrCmsResponse(response.data);
        // log.debug(JSON.stringify(parsedCmsResponse, null, 2));
        state.cmsData = parsedCmsResponse;

        const cmsLabels = _.filter(parsedCmsResponse, (cmsEntry) => {
          return cmsEntry.DocumentType === "labels";
        });
        let cmsTranslationData = {};
        _.forEach(cmsLabels, (labelEntry) => {
          const backendFieldName = _.get(
            labelEntry,
            "de.data.backend_field_name[0].text",
          );
          cmsTranslationData[backendFieldName] = {
            de: labelEntry.de.data.label[0].text,
            fr: labelEntry.fr.data.label[0].text,
            en: labelEntry.en.data.label[0].text,
          };
        });
        console.groupCollapsed("cmsTranslationData");
        log.debug(JSON.stringify(cmsTranslationData, null, 2));
        console.groupEnd();
        state.cmsTranslationData = cmsTranslationData;

        commit("setCmsPageData");
      });
    },
    exportGraph() {
      // eslint-disable-next-line vue/custom-event-name-casing
      nanoEmitter.emit("exportGraph");
    },
    showMobileMenu({ commit }) {
      commit("setMobileMenuShown", true);
    },
    hideMobileMenu({ commit }) {
      commit("setMobileMenuShown", false);
    },
    setDataStoriesTagFilterShown({ commit }, value) {
      commit("setDataStoriesTagFilterShown", value);
    },
  },
});
