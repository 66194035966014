<template>
  <div class="active-grant-filter" v-if="filterValue">
    <div class="entity-title" v-if="filterValue.entityTitleKey">
      {{ cmsTranslationByKey(filterValue.entityTitleKey) }}:
    </div>

    <div class="filter-title">
      {{ filterValue.title }}
    </div>

    <div class="filter-reset" @click="resetFilter">
      <img
        src="/img/reset-search.svg"
        style="width: 10px; height: 10px"
        alt="reset-search"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { cmsTranslationMixin } from "@/mixins";
import { nanoEmitter } from "@/events";

export default {
  name: "ActiveGrantFilter",
  mixins: [cmsTranslationMixin],
  props: ["filterValue"],
  components: {},
  computed: {
    ...mapState(["grantShowFilterMobile", "renderActiveFiltersCount"]),
    ...mapGetters(["findGrantFilterValue"]),
  },
  methods: {
    resetFilter() {
      if (this.filterValue) {
        if (this.filterValue.type === "multi") {
          nanoEmitter.emit("changeQueryParam", {
            queryParam: "multi",
            value: null,
            refetch: true,
          });
        } else if (this.filterValue.type === "date") {
          this.$emit("toggle-date-filter", this.filterValue.urlQueryParam);
        } else {
          this.$emit(
            "toggle-search-filter",
            this.findGrantFilterValue(this.filterValue.value),
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.active-grant-filter {
  background: $snf-blue-highlight;
  display: flex;
  margin-right: 16px;
  margin-bottom: 8px;
  padding: 4px 12px;
  align-items: center;

  .entity-title {
    margin-right: 4px;
  }

  .filter-title {
    margin-right: 8px;
  }

  .filter-reset {
    cursor: pointer;
  }
}
</style>
